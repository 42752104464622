import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCategory } from "../../redux/features/categorySlice";
import { message } from "antd";
import SideMenu from "./SideMenu";
import Backdrop from "./Backdrop";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import "./Header.css";
import { setEvent } from "../../redux/features/eventSlice";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { category } = useSelector((state) => state.category);
  const { event } = useSelector((state) => state.event);
  const [sideMenu, setSideMenu] = useState(false);

  async function getMenus() {
    try {
      const res = await axios.get("/api/admin/get-category");
      if (res.data.success) {
        const menus = res?.data?.data?.filter(
          (item) => item?.category !== "Event"
        );
        dispatch(setCategory(menus));
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getMenus();
  }, []);

  function handleCall() {
    window.open("tel:+919321252909");
  }

  // GET EVENT
  const getEvent = async () => {
    try {
      const res = await axios.get("/api/event/get-events");
      if (res.data.success) {
        dispatch(setEvent(res.data.data));
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getEvent();
  }, []);

  return (
    <header className={`header-main`}>
      <SideMenu setSideMenu={setSideMenu} sideMenu={sideMenu} />
      <Backdrop setSideMenu={setSideMenu} sideMenu={sideMenu} />
      <div className="before-header d-none d-lg-flex">
        <div className="logo" onClick={() => navigate("/")}>
          ATLANTIS
        </div>
        {location?.pathname !== "/search" && (
          <div className="search" onClick={() => navigate("/search")}>
            <input type="text" placeholder="Where do you want to go?" />
          </div>
        )}
        <ul className="menus">
          {event && event.display === "yes" && (
            <li onClick={() => navigate("/event")}>{event?.name}</li>
          )}
          <li onClick={() => navigate("/contact")}>Contact Us</li>
          <li onClick={() => navigate("/about")}>About Us</li>
        </ul>
        <div className="contact">
          <button className="roundbtn" onClick={handleCall}>
            +91 9321252909
          </button>
        </div>
      </div>
      <div className="header d-none d-lg-flex">
        <ul className="menus">
          {category &&
            category?.map((item, index) => {
              return (
                <li
                  key={index}
                  onClick={() =>
                    navigate(`/category/${item?.category.replace(" ", "-")}`)
                  }
                >
                  {item?.category}
                </li>
              );
            })}
        </ul>
      </div>
      <div className="header d-flex d-md-flex d-lg-none">
        <div className="logo" onClick={() => navigate("/")}>
          ATLANTIS
        </div>
        <div className="contact">
          <button className="roundbtn" onClick={handleCall}>
            +91 9321252909
          </button>
        </div>
        <div className="icons">
          {location.pathname !== "/search" && (
            <SearchIcon onClick={() => navigate("/search")} className="icon" />
          )}
          <MenuIcon onClick={() => setSideMenu(!sideMenu)} className="icon" />
        </div>
      </div>
    </header>
  );
};

export default Header;
