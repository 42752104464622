import React from "react";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
import HikingIcon from "@mui/icons-material/Hiking";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import "./WhyUs.css";

const WhyUs = () => {
  return (
    <div className="whyus-container">
      <h2 className="text-center">Reason To Choose Us!</h2>
      <div className="whyus">
        <div className="why">
          <p>
            At Atlantis, we prioritize personalized communication with our
            travelers. By operating a direct-to-consumer model, we ensure that
            travelers engage directly with us, eliminating the need for
            intermediaries or agents.
          </p>
          <hr />
          <div className="whycontent">
            <h4>Direct Engagement Model</h4>
            <div className="icon">
              <HowToRegIcon className="icon" />
            </div>
          </div>
        </div>
        <div className="why">
          <p>
            Our seasoned trip leaders accompany every travel group, ensuring a
            smooth and safe journey for everyone, with a special focus on women
            travelers. We take pride in the fact that nearly half of our
            travelers are women.
          </p>
          <hr />
          <div className="whycontent">
            <h4>Half of Our Travelers Are Women</h4>
            <div className="icon">
              <PersonPinCircleIcon className="icon" />
            </div>
          </div>
        </div>
        <div className="why">
          <p>
            Our travel experts are committed to providing genuine insights,
            never sugarcoating the reality of any situation. This open
            communication has allowed us to build a strong foundation of honesty
            and transparency with our travelers.
          </p>
          <hr />
          <div className="whycontent">
            <h4>Traveler Transparency</h4>
            <div className="icon">
              <HikingIcon className="icon" />
            </div>
          </div>
        </div>
        <div className="why">
          <p>
            To ensure a smooth travel experience, we filter travelers by age,
            comfort with traveling with the opposite gender, and other factors.
            This helps us create groups where everyone can enjoy the journey.
          </p>
          <hr />
          <div className="whycontent">
            <h4>Customized Travel Companionship</h4>
            <div className="icon">
              <FilterAltIcon className="icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyUs;
