import React, { useEffect, useRef, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import axios from "axios";
import { message } from "antd";
import "./AdminExplore.css";

const AdminExplore = () => {
  const imageRef = useRef();
  const [locations, setLocations] = useState(null);
  const [data, setData] = useState(null);
  const [explore, setExplore] = useState({
    image: "",
    name: "",
    location: "",
  });

  function handleChange(e) {
    const { name, value, files } = e.target;
    if (name === "image") {
      setExplore({ ...explore, image: files[0] });
    } else {
      setExplore({ ...explore, [name]: value });
    }
  }

  async function handleSubmit() {
    if (explore?.location === "") {
      return message.error("Select Location");
    }
    try {
      const formData = new FormData();
      formData.append("name", explore.name);
      formData.append("image", explore.image);
      formData.append("location", explore.location);
      const res = await axios.post("/api/admin/add-explore", formData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        setExplore({ image: "", name: "", location: "" });
        getExplore();
        if (imageRef.current) {
          imageRef.current.value = "";
        }
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getLocations = async () => {
    try {
      const res = await axios.get("/api/admin/get-locations");
      if (res.data.success) {
        setLocations(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getExplore = async () => {
    try {
      const res = await axios.get("/api/admin/get-explore");
      if (res.data.success) {
        setData(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const res = await axios.post(
        "/api/admin/delete-explore",
        { id: id },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
        getExplore();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLocations();
    getExplore();
  }, []);

  return (
    <AdminLayout>
      <div className="admin-explore">
        <div className="page-title">
          <h3 className="m-0">Explore Slider</h3>
        </div>
        <hr />
        {/* explore form  */}
        <div className="explore-form">
          <div className="form-fields">
            <input
              ref={imageRef}
              onChange={handleChange}
              type="file"
              className="form-control"
              name="image"
            />
          </div>
          <div className="form-fields">
            <small>Enter name</small>
            <input
              value={explore?.name}
              onChange={handleChange}
              type="text"
              className="form-control"
              name="name"
            />
          </div>
          <div className="form-fields">
            <small>Select Location</small>
            <select
              value={explore?.location}
              onChange={handleChange}
              name="location"
              className="form-control"
            >
              <option value="">Select</option>
              {locations?.map((item, index) => (
                <option key={index} value={item?.tourName}>
                  {item?.tourName}
                </option>
              ))}
            </select>
          </div>
          <button onClick={handleSubmit} className="squarebtn">
            Add
          </button>
        </div>
        {/* explore data */}
        <table className="mt-5 table table-bordered explore-list">
          <thead>
            <tr>
              <th>SrNo</th>
              <th>Image</th>
              <th>Name</th>
              <th>Location</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      <img src={item?.image} alt="" />
                    </td>
                    <td>{item?.name}</td>
                    <td>{item?.location}</td>
                    <td>
                      <button
                        className="btn btn-danger"
                        onClick={() => handleDelete(item?._id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </AdminLayout>
  );
};

export default AdminExplore;
