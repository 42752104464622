import React, { useState } from "react";
import { message } from "antd";
import IMAGES from "../img/image";
import axios from "axios";
import "./ContactForm.css";

const ContactForm = () => {
  const [form, setForm] = useState({ name: "", email: "", mobile: "" });
  const [error, setError] = useState(false);

  function handleChange(e) {
    setForm({ ...form, [e.target.name]: e.target.value });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (form?.name === "" || form?.email === "" || form?.mobile === "") {
      setError(true);
      return;
    }
    if (isNaN(form.mobile)) {
      return message.error("Please enter only numbers in mobile");
    }
    if (form.mobile.length > 10 || form?.mobile.length < 10) {
      return message.error("Enter 10 digits in mobile");
    }
    try {
      const res = await axios.post("/api/contact/add-contact-form", form);
      if (res.data.success) {
        message.success(res.data.message);
        setForm({ name: "", email: "", mobile: "", msg: "" });
        setError(false);
      } else {
        message.error(res.data.message);
        setError(false);
      }
    } catch (error) {
      setError(false);
      console.log(error);
    }
  };

  return (
    <div className="home-form-container">
      <div className="formm">
        <div className="form-image">
          <img src={IMAGES.contactform2} alt="" />
        </div>
        <div className="cform">
          <p>
            Not sure what to do? We'll give you a <span>Call Back</span>
          </p>
          <div className="form-fields">
            <label>Name</label>
            <input
              type="text"
              className="form-control"
              name="name"
              placeholder="Enter your name"
              onChange={handleChange}
              value={form?.name}
            />
            {error && form?.name === "" && (
              <span className="text-start text-danger d-block">
                <small>Enter name</small>
              </span>
            )}
          </div>
          <div className="form-fields">
            <label>Email</label>
            <input
              type="text"
              className="form-control"
              name="email"
              placeholder="Enter your email"
              onChange={handleChange}
              value={form?.email}
            />
            {error && form?.email === "" && (
              <span className="text-start text-danger d-block">
                <small>Enter email</small>
              </span>
            )}
          </div>
          <div className="form-fields">
            <label>Mobile</label>
            <input
              type="text"
              className="form-control"
              name="mobile"
              placeholder="Enter your phone"
              onChange={handleChange}
              value={form?.mobile}
            />
            {error && form?.mobile === "" && (
              <span className="text-start text-danger d-block">
                <small>Enter mobile</small>
              </span>
            )}
          </div>
          <button onClick={handleSubmit}>Submit</button>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
