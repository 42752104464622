import React, { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout/Layout";
import OurStory from "../components/Home/OurStory";
import Slider from "react-slick";
import IMAGES from "../img/image";
import OurTeam from "../components/About/OurTeam";
import "./About.css";

const About = () => {
  const [tab, setTab] = useState("Our Story");
  const [fixed, setFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight) {
        setFixed(true);
      } else {
        setFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const arrowRef = useRef(null);
  const OurStoryRef = useRef(null);
  const valuesMissionRef = useRef(null);
  const whyUsRef = useRef(null);
  const ourTeamRef = useRef(null);

  const handleClick = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  var settings = {
    dots: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    infinite: true,
    nextArrow: false,
    prevArrow: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const whyUsArray = [
    {
      icon: IMAGES.ab1,
      heading: "Expert Local Insights",
      desc: "Our dedicated specialists create immersive tours that captivate travelers with local culture. We offer multilingual services in German, French, and Spanish to meet diverse needs.",
    },
    {
      icon: IMAGES.ab2,
      heading: "Passionate Product Team",
      desc: "We focus on experiential travel with unique adventures and all the tools needed to differentiate your offerings, from video itineraries to curated hotel recommendations.",
    },
    {
      icon: IMAGES.ab3,
      heading: "Purposeful Travel",
      desc: "We support responsible tourism through projects aligned with our core values, including environmental sustainability, a no-plastic policy, and paperless initiatives.",
    },
    {
      icon: IMAGES.ab4,
      heading: "Exceptional 24/7 Support",
      desc: "We offer round-the-clock assistance nationwide. Our team and local partners consistently deliver exceptional service before, during, and after your journey.",
    },
    {
      icon: IMAGES.ab5,
      heading: "Exceptional Benefits",
      desc: "Our strong relationships with hotels and suppliers ensure that our clients enjoy outstanding advantages through superior purchasing power and exclusive benefits.",
    },
    {
      icon: IMAGES.ab7,
      heading: "Comprehensive Marketing & Training",
      desc: "Our team provides extensive support with your brochure, content, and high-resolution images, while staying updated on the latest trends to suit your needs.",
    },
    {
      icon: IMAGES.ab10,
      heading: "Crisis Management Team",
      desc: "We have a comprehensive crisis management process to ensure the safety and security of all our guests during their travels with us, ensuring peace of mind.",
    },
    {
      icon: IMAGES.ab11,
      heading: "Dedicated On-Site Team",
      desc: "We invest in training our guides, drivers, and local partners, fostering innovation to ensure our team meets guest expectations and delivers exceptional service.",
    },
  ];

  return (
    <Layout>
      <div className="about-container">
        <div className="bg-overlay">
          <h1 className="hero-title">About Us</h1>
        </div>
      </div>
      <div className={`${fixed && "topfixed"} about-tabs`}>
        <h2
          className={`${tab === "Our Story" && "active"}`}
          onClick={() => {
            setTab("Our Story");
            handleClick(OurStoryRef);
          }}
        >
          Our Story
        </h2>
        <h2
          className={`${tab === "Values and Mission" && "active"}`}
          onClick={() => {
            setTab("Values and Mission");
            handleClick(valuesMissionRef);
          }}
        >
          Values and Mission
        </h2>
        <h2
          className={`${tab === "Why Us" && "active"}`}
          onClick={() => {
            setTab("Why Us");
            handleClick(whyUsRef);
          }}
        >
          Why Us
        </h2>
        <h2
          className={`${tab === "Our Team" && "active"}`}
          onClick={() => {
            setTab("Our Team");
            handleClick(ourTeamRef);
          }}
        >
          Our Team
        </h2>
      </div>

      {/* OUR STORY */}
      <div ref={OurStoryRef}>
        <OurStory />
      </div>

      {/* VALUES AND MISSION  */}
      <div ref={valuesMissionRef} className="values-and-mission">
        <div className="left order-2 order-lg-1">
          <h2>Values and Mission</h2>
          <p>
            At Atlantis, we build our success on a foundation of trust and
            reliability. Our extensive network of esteemed travel partners and
            in-depth regional knowledge allow us to craft personalized travel
            experiences that cater to the unique preferences of each client.
            This commitment to customization distinguishes us in the travel
            industry.
          </p>
          <p>
            At Atlantis, we build our success on a foundation of trust and
            reliability. Our extensive network of esteemed travel partners and
            in-depth regional knowledge allow us to craft personalized travel
            experiences that cater to the unique preferences of each client.
            This commitment to customization distinguishes us in the travel
            industry. Our goal is to be a steadfast partner for our business
            collaborators and destination service resellers. By staying ahead of
            the curve with the changing demands of today’s travelers, we excel
            in designing memorable journeys. Driven by enthusiasm and
            dedication, we ensure that every interaction with our partners and
            their clients is marked by our genuine passion for travel.
          </p>
          <p>
            We are your dedicated partner, infusing every aspect of our service
            with authenticity and enthusiasm to make travel experiences seamless
            and exceptional for every traveler.
          </p>
        </div>
        <div className="right order-1 order-lg-2"></div>
      </div>

      {/* WHY US  */}
      <div ref={whyUsRef} className="why-us">
        <h1 className="cursive">Why Us?</h1>
        <p>
          At Atlantis, our focus extends beyond the stunning destinations we
          guide you to; we are dedicated to crafting extraordinary journeys that
          make every moment of your trip memorable. It’s the exceptional
          experiences we create along the way that truly set us apart.
        </p>
        {/* <p>
          From the initial concept to the final review, we act as your dedicated
          guide every step of the way. Our exceptional team is committed to
          delivering authentic and innovative destination experiences, while
          also securing the best rates for you.
        </p> */}
        <Slider ref={arrowRef} {...settings}>
          {whyUsArray?.map((item, index) => {
            return (
              <div className="why-us-feature">
                <div className="feature">
                  <div className="icon">
                    <img src={item?.icon} alt="" />
                  </div>
                  <h4>{item?.heading}</h4>
                  <span>{item.desc}</span>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>

      {/* OUR TEAM  */}
      <div ref={ourTeamRef}>
        <OurTeam />
      </div>
    </Layout>
  );
};

export default About;
