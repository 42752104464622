import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { message, Table, Pagination, Select, Modal, Button } from "antd";
import "./AdminQueries.css";

const { Option } = Select;

const AdminQueries = () => {
  const [queries, setQueries] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [statusFilter, setStatusFilter] = useState(null);
  const [singleQuery, setSingleQuery] = useState(null);
  const [view, setView] = useState(0);
  const [msg, setMsg] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const getAllQueries = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/api/admin/get-all-queries", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setQueries(res.data.data.reverse());
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSeen = async (id) => {
    try {
      const res = await axios.post(
        "/api/admin/query-seen",
        { id: id },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
        getAllQueries();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllQueries();
  }, []);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (current, size) => {
    setPageSize(size);
  };

  const handleStatusFilterChange = (value) => {
    setStatusFilter(value);
  };

  const handleView = (record) => {
    setSingleQuery(record);
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const filteredQueries =
    queries &&
    queries.filter((item) => {
      if (statusFilter) {
        return item.status === statusFilter;
      } else {
        return true;
      }
    });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => `${record.name}`,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
    },
  ];

  return (
    <AdminLayout>
      <div className="page-title">
        <h3 className="m-0">Queries</h3>
        <h6>Total Queries - {queries?.length}</h6>
      </div>
      <hr />
      {view === 0 && (
        <div className="table-container">
          <div className="tools">
            <Select
              className="mr-2"
              style={{ width: 200 }}
              placeholder="Filter by Status"
              onChange={handleStatusFilterChange}
            >
              <Option value="">All Status</Option>
              <Option value="pending">Pending</Option>
              <Option value="seen">Seen</Option>
            </Select>
          </div>
          <Table
            dataSource={filteredQueries?.slice(
              (currentPage - 1) * pageSize,
              currentPage * pageSize
            )}
            columns={columns}
            pagination={false}
            rowKey="_id"
          />
          <Pagination
            className="my-3"
            current={currentPage}
            pageSize={pageSize}
            total={queries?.length}
            onChange={handlePageChange}
            showSizeChanger
            onShowSizeChange={handlePageSizeChange}
          />
        </div>
      )}
      <Modal
        title="Query Details"
        visible={isModalVisible}
        onCancel={handleClose}
        footer={[
          <Button key="close" onClick={handleClose}>
            Close
          </Button>,
        ]}
      >
        {singleQuery?.msg}
      </Modal>
    </AdminLayout>
  );
};

export default AdminQueries;
