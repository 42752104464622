import React from "react";
import StarIcon from "@mui/icons-material/Star";
import IMAGES from "../../img/image";
import "./OurRatings.css";

const OurRatings = () => {
  return (
    <div className="ratings-container">
      <div className="ratings">
        <div className="img">
          <img src={IMAGES.google} alt="" />
        </div>
        <div className="content">
          <StarIcon className="icon" />
          <span>4.9</span>
          <p>(1000+ Reviews)</p>
        </div>
      </div>
      <div className="ratings">
        <div className="img">
          <img src={IMAGES.tripadvisor} alt="" />
        </div>
        <div className="content">
          <StarIcon className="icon" />
          <span>5.0</span>
          <p>(1000+ Reviews)</p>
        </div>
      </div>
      <div className="ratings">
        <div className="img">
          <img src={IMAGES.facebook} alt="" />
        </div>
        <div className="content">
          <StarIcon className="icon" />
          <span>4.9</span>
          <p>(1000+ Reviews)</p>
        </div>
      </div>
    </div>
  );
};

export default OurRatings;
