import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { message } from "antd";
import { useParams } from "react-router-dom";

const Gallery = ({
  handleFileChange,
  handleTourFileChange,
  handleBannerFileChange,
  getLocation,
  form,
}) => {
  const params = useParams();

  async function handleDeleteImage(index) {
    try {
      const res = await axios.post(
        "/api/admin/delete-gallery-img",
        { id: params?.id, index: index },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
        getLocation();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      message.error(error.message);
    }
  }

  return (
    <div className="form-container">
      <div>
        {/* <div className="form-fields mb-4">
          <label className="form-label" htmlFor="gallery">
            Gallery
          </label>
          <input
            type="file"
            className="form-control"
            name="gallery"
            multiple
            onChange={handleFileChange}
          />
          <div className="gallery-preview">
            {form?.gallery?.map((item, index) => {
              return (
                <div className="gallery-img-box">
                  <DeleteIcon
                    onClick={() => handleDeleteImage(index)}
                    className="icon"
                  />
                  <img
                    className="me-2 mt-3 rounded"
                    width="100px"
                    src={item}
                    alt="img"
                  />
                </div>
              );
            })}
          </div>
        </div> */}
        <div className="form-fields mb-4">
          <label className="form-label" htmlFor="gallery">
            Tour Image
          </label>
          <input
            type="file"
            className="form-control"
            name="gallery"
            onChange={handleTourFileChange}
          />
        </div>
        <div className="form-fields mb-4">
          <label className="form-label" htmlFor="gallery">
            Banner Image
          </label>
          <input
            type="file"
            className="form-control"
            name="gallery"
            onChange={handleBannerFileChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Gallery;
