import React from "react";
import { Link } from "react-router-dom";
import "./Faq.css";

const Faq = ({ dest }) => {
  return (
    <React.Fragment>
      <div className="faq-container">
        <h4 className="faq">Frequently Asked Question</h4>
        <div className="accordion accordion-flush" id="accordionFlushExample">
          {dest?.faqs?.map((item, index) => {
            return (
              <div className="accordion-item">
                <h4 className="accordion-header" id={`flush-heading${index}`}>
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#flush-collapse${index}`}
                    aria-expanded="false"
                    aria-controls={`flush-collapse${index}`}
                  >
                    Q: {item?.title}
                  </button>
                </h4>
                <div
                  id={`flush-collapse${index}`}
                  className="accordion-collapse collapse"
                  aria-labelledby={`flush-heading${index}`}
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">{item?.description}</div>
                </div>
              </div>
            );
          })}

          <span>
            Feel free to <Link to="/contact">contact us</Link> if you have more
            questions or need assistance! Your satisfaction is our priority.
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Faq;
