import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Slider from "react-slick";
import "./InternationalPackages.css";

const IndianPackages = ({ locations, videos }) => {
  const arrowRef = useRef();
  const navigate = useNavigate();

  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    speed: 500,
    nextArrow: false,
    prevArrow: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          dots: false,
        },
      },
      {
        breakpoint: 400,
        settings: {
          dots: false,
        },
      },
    ],
  };

  const filterLocations = locations?.filter((item) =>
    item.categories?.includes("Domestic Packages")
  );

  if (!filterLocations || filterLocations.length === 0) {
    return null;
  }

  return (
    <div className="internation-container">
      <div className="video-container">
        <div className="titles">
          <h3>Indian Trips</h3>
          <p>A Journey Through Time, Colour And Culture</p>
          <button
            className="explorebtn"
            onClick={() => navigate("/category/Domestic-Packages")}
          >
            Explore
          </button>
        </div>
        <video
          playsInline
          poster
          autoPlay
          muted
          loop
          src={videos && videos?.indVideo}
        ></video>
      </div>
      <div className="int-locations">
        <Slider ref={arrowRef} {...settings}>
          {filterLocations?.map((item, index) => {
            return (
              <div
                className="location-box"
                onClick={() => navigate(`/destination/${item?.tourName}`)}
              >
                <div className="location">
                  <img
                    src={`https://atlantisholidayz.com/${item?.tourImage}`}
                    alt=""
                  />
                  <div className="content">{item?.tourName}</div>
                </div>
              </div>
            );
          })}
        </Slider>
        <div className="int-buttons">
          <div onClick={() => arrowRef.current.slickPrev()}>
            <KeyboardArrowLeftIcon className="icon left" />
          </div>
          <div onClick={() => arrowRef.current.slickNext()}>
            <KeyboardArrowRightIcon className="icon right" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndianPackages;
