import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import { useNavigate, useParams } from "react-router-dom";
import { message, Modal } from "antd";
import axios from "axios";
import BasicInfo from "./locationComponents/BasicInfo";
import Gallery from "./locationComponents/Gallery";
import Package from "./locationComponents/Package";
import Usefulinfo from "./locationComponents/Usefulinfo";
import Faq from "./locationComponents/Faq";
import Activity from "./locationComponents/Activity";
import "./AdminAddEditLocations.css";

const AdminAddEditLocations = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [step, setStep] = useState(0);
  const [regions, setRegions] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [data, setData] = useState(null);
  const [index, setIndex] = useState(null);

  const [form, setForm] = useState({
    name: "",
    tourName: "",
    description: "",
    overview: "",
    tourImage: "",
    bannerImage: "",
    gallery: [],
    regions: [],
    usefulInformation: [],
    faqs: [],
    categories: [],
    packages: [],
    activities: [],
  });

  const steps = [
    "Location",
    "Tour Img, Banner Img, Gallery",
    "Package",
    "Activity",
    "Faqs",
    // "Faqs",
  ];

  // ======================= GET LOCATION
  async function getLocation() {
    try {
      const res = await axios.post(
        "/api/admin/get-location",
        {
          id: params.id,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setRegions(res.data.data.regions);
        setForm(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (params.id) {
      getLocation();
    }
  }, [params]);
  // ======================= GET LOCATION
  // ======================= GET LOCATION

  function handleChange(e) {
    setForm({ ...form, [e.target.name]: e.target.value });
  }

  function handleFileChange(e) {
    const files = Array.from(e.target.files);
    setForm({ ...form, gallery: [...form?.gallery, ...files] });
  }
  function handleTourFileChange(e) {
    setForm({ ...form, tourImage: e.target.files[0] });
  }
  function handleBannerFileChange(e) {
    setForm({ ...form, bannerImage: e.target.files[0] });
  }

  function handleAddUsefulInfo() {
    const infoTitle = document.getElementById("info-title").value;
    const infoDescription = document.getElementById("info-description").value;
    setForm({
      ...form,
      usefulInformation: [
        ...form?.usefulInformation,
        { title: infoTitle, description: infoDescription },
      ],
    });

    document.getElementById("info-title").value = "";
    document.getElementById("info-description").value = "";
  }

  function handleRemoveUsefulInfo(index) {
    const newUsefulInfo = form?.usefulInformation.filter((_, i) => i !== index);
    setForm({ ...form, usefulInformation: newUsefulInfo });
  }

  function handleAddFaq() {
    const faqTitle = document.getElementById("faq-title").value;
    const faqDescription = document.getElementById("faq-description").value;
    setForm({
      ...form,
      faqs: [...form?.faqs, { title: faqTitle, description: faqDescription }],
    });

    document.getElementById("faq-title").value = "";
    document.getElementById("faq-description").value = "";
  }

  function handleRemoveFaq(index) {
    const newFaq = form?.faqs.filter((item, i) => i !== index);
    setForm({ ...form, faqs: newFaq });
  }

  async function handleSubmit() {
    try {
      const formData = new FormData();
      formData.append("name", form?.name);
      formData.append("tourName", form?.tourName);
      formData.append("description", form?.description);
      formData.append("overview", form?.overview);
      formData.append("tourImage", form?.tourImage);
      formData.append("bannerImage", form?.bannerImage);

      if (params.id) {
        formData.append("id", params.id);
      }

      form?.categories.forEach((category, index) => {
        formData.append(`categories[${index}]`, category);
      });

      form?.gallery.forEach((file, index) => {
        formData.append(`gallery[${index}]`, file);
      });

      form?.usefulInformation.forEach((info, index) => {
        formData.append(`usefulInformation[${index}][title]`, info.title);
        formData.append(
          `usefulInformation[${index}][description]`,
          info.description
        );
      });

      form?.faqs.forEach((item, index) => {
        formData.append(`faqs[${index}][title]`, item.title);
        formData.append(`faqs[${index}][description]`, item.description);
      });

      form?.packages.forEach((pkg, pkgIndex) => {
        formData.append(`packages[${pkgIndex}][id]`, pkg.id);
        formData.append(`packages[${pkgIndex}][name]`, pkg.name);

        pkg.packageArray.forEach((pkgItem, itemIndex) => {
          formData.append(
            `packages[${pkgIndex}][packageArray][${itemIndex}][packageId]`,
            pkgItem.packageId
          );
          formData.append(
            `packages[${pkgIndex}][packageArray][${itemIndex}][title]`,
            pkgItem.title
          );
          if (pkgItem.image instanceof File) {
            formData.append(
              `packages[${pkgIndex}][packageArray][${itemIndex}][image]`,
              pkgItem.image
            );
          } else {
            formData.append(
              `packages[${pkgIndex}][packageArray][${itemIndex}][image]`,
              pkgItem.image // Assuming this is a URL or some identifier
            );
          }
          formData.append(
            `packages[${pkgIndex}][packageArray][${itemIndex}][time]`,
            pkgItem.time
          );
          formData.append(
            `packages[${pkgIndex}][packageArray][${itemIndex}][location]`,
            pkgItem.location
          );
        });
      });

      form?.activities.forEach((act, actIndex) => {
        formData.append(`activities[${actIndex}][id]`, act.id);
        formData.append(`activities[${actIndex}][name]`, act.name);
        formData.append(
          `activities[${actIndex}][description]`,
          act.description
        );

        act.activityArray.forEach((actItem, itemIndex) => {
          formData.append(
            `activities[${actIndex}][activityArray][${itemIndex}][activityId]`,
            actItem.activityId
          );
          formData.append(
            `activities[${actIndex}][activityArray][${itemIndex}][title]`,
            actItem.title
          );
          formData.append(
            `activities[${actIndex}][activityArray][${itemIndex}][description]`,
            actItem.description
          );
          if (actItem.image instanceof File) {
            formData.append(
              `activities[${actIndex}][activityArray][${itemIndex}][image]`,
              actItem.image
            );
          } else {
            formData.append(
              `activities[${actIndex}][activityArray][${itemIndex}][image]`,
              actItem.image
            );
          }
          formData.append(
            `activities[${actIndex}][activityArray][${itemIndex}][time]`,
            actItem.time
          );
          formData.append(
            `activities[${actIndex}][activityArray][${itemIndex}][location]`,
            actItem.location
          );
        });
      });

      const res = await axios.post("/api/admin/add-location", formData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "multipart/form-data", // Ensure correct content type for FormData
        },
      });

      if (res.data.success) {
        navigate("/admin-locations");
        message.success(res.data.message);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <AdminLayout>
      <div className="admin-users-container">
        <div className="page-title">
          <h3 className="m-0">
            Step {step + 1} : {steps[step]}
          </h3>
        </div>
        <hr />

        {/* STEP 1 BASIC INFO  */}
        {step === 0 && (
          <div className="form-container">
            <BasicInfo
              handleChange={handleChange}
              form={form}
              setForm={setForm}
            />
          </div>
        )}

        {/* STEP 2  GALLERY ARRAY */}
        {step === 1 && (
          <Gallery
            form={form}
            handleFileChange={handleFileChange}
            handleTourFileChange={handleTourFileChange}
            handleBannerFileChange={handleBannerFileChange}
            getLocation={getLocation}
          />
        )}

        {/* STEP 3  REGION ARRAY */}
        {step === 2 && <Package form={form} setForm={setForm} />}

        {step === 3 && <Activity form={form} setForm={setForm} />}

        {/* STEP 4 USEFUL INFORMATION ARRAY */}
        {/* HIDDEN SINCE NO USE */}
        {step === 10 && (
          <Usefulinfo
            form={form}
            handleAddUsefulInfo={handleAddUsefulInfo}
            handleRemoveUsefulInfo={handleRemoveUsefulInfo}
          />
        )}

        {/* STEP 5  FAQ ARRAY */}
        {step === 4 && (
          <Faq
            form={form}
            handleAddFaq={handleAddFaq}
            handleRemoveFaq={handleRemoveFaq}
          />
        )}

        <div className="steps-buttons">
          {step === 0 && <span></span>}
          {step !== 0 && (
            <button className="theme-btn" onClick={() => setStep(step - 1)}>
              Prev
            </button>
          )}
          {step !== 4 && (
            <button
              className="theme-btn"
              onClick={() => {
                setStep(step + 1);
                console.log(form);
              }}
            >
              Next
            </button>
          )}
          {step === 4 && params.id ? (
            <button className="theme-btn" onClick={handleSubmit}>
              Update
            </button>
          ) : (
            step === 4 && (
              <button className="theme-btn" onClick={handleSubmit}>
                Submit
              </button>
            )
          )}
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminAddEditLocations;
