import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import { message } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./AdminLocations.css";

const AdminLocations = () => {
  const navigate = useNavigate();
  const [locations, setLocations] = useState(null);

  // delete user
  const handleDeleteLocation = async (id) => {
    const confirm = window.confirm("Are you sure to delete?");
    if (confirm) {
      try {
        const res = await axios.post(
          "/api/admin/delete-location",
          { id },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        if (res.data.success) {
          message.success(res.data.message);
          getLocations();
        } else {
          message.error(res.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getLocations = async () => {
    try {
      const res = await axios.get("/api/admin/get-locations", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setLocations(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLocations();
  }, []);

  return (
    <AdminLayout>
      <div className="admin-users-container admin-locations">
        <div className="page-title">
          <div className="d-flex align-items-center">
            <h3 className="m-0">Locations</h3>
            <button
              className="theme-btn ms-2"
              onClick={() => navigate("/admin-add-edit-locations")}
            >
              Add New
            </button>
          </div>
          <h6 className="m-0">Total Locations - {locations?.length || 0}</h6>
        </div>
        <hr />

        <div className="table-container">
          <table className="table user-table">
            <thead>
              <tr>
                <th>Sr No</th>
                <th>Image</th>
                <th>Tour Name</th>
                <th>Category</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {locations?.map((item, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>
                      <img src={item?.tourImage} alt="img" />
                    </td>
                    <td>{item?.tourName}</td>
                    <td>
                      {item?.categories?.map((item) => {
                        return <span>{item}</span>;
                      })}
                    </td>
                    <td>
                      <button
                        className="theme-btn py-2 me-2 bg-warning"
                        onClick={() =>
                          navigate(`/admin-add-edit-locations/${item?._id}`)
                        }
                      >
                        Edit
                      </button>
                      <button
                        className="theme-btn py-2 bg-danger"
                        onClick={() => handleDeleteLocation(item?._id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminLocations;
