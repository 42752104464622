import React from "react";
import "./OurStory.css";
import { useLocation, useNavigate } from "react-router-dom";

const OurStory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div className="our-story">
      <div className="content">
        <h1>Our Story</h1>
        <p>
          Welcome to Atlantis, a premier Company dedicated to creating
          extraordinary travel experiences across India, Nepal, Bhutan, and Sri
          Lanka. Since our establishment, we have carefully designed each
          journey to ensure excellence at every step. Our expertly curated team
          is committed to surpassing your expectations, supported by solid
          partnerships and financial stability. We are dedicated to
          sustainability and community empowerment, offering enriching travel
          experiences that make a meaningful impact. Trust Atlantis to turn your
          travel dreams into reality with seamless and unforgettable journeys
          that leave a lasting impression.
        </p>
        {location.pathname !== "/about" && (
          <button
            className="theme-btn-2 d-block m-auto"
            onClick={() => navigate("/about")}
          >
            Read More
          </button>
        )}
      </div>
    </div>
  );
};

export default OurStory;
