import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../Home/OurStory.css";
import "../OurTeam.css";

const OurTeam = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const teamArray = [
    { name: "Ansari Ashir", role: "Full Stack Web Developer" },
    { name: "Ansari Ashir", role: "Full Stack Web Developer" },
    { name: "Ansari Ashir", role: "Full Stack Web Developer" },
    { name: "Ansari Ashir", role: "Full Stack Web Developer" },
  ];

  return (
    <div className="our-story our-team">
      <div className="overlay">
        <div className="content">
          <h1>Our Team</h1>
          <p>
            At Atlantis, we bring together a team of passionate experts with a
            wide range of skills, all committed to delivering outstanding travel
            experiences across India, Nepal, Bhutan, and Sri Lanka. From
            orchestrating seamless operations and designing tailor-made
            itineraries to handling customer care and sharing deep cultural
            knowledge, every team member contributes to ensuring travelers enjoy
            immersive journeys that highlight the region's vibrant heritage and
            natural beauty. Our shared goal is to create bespoke experiences
            that go beyond expectations, fostering lasting memories and
            authentic connections with the unique destinations we showcase.
          </p>
          {/* <div className="team-box">
            {teamArray?.map((item, index) => {
              return (
                <div className="team">
                  <h4>Ansari Ashir</h4>
                  <p>Full Stack Web Developer</p>
                </div>
              );
            })}
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default OurTeam;
