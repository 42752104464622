import React, { useEffect, useRef, useState } from "react";
import { message } from "antd";
import { Cloudinary } from "cloudinary-core";
import AdminLayout from "./components/AdminLayout";
import axios from "axios";
import "./AdminHeroSection.css";

const AdminHeroSection = () => {
  const [heroVideo, setHeroVideo] = useState(null);
  const [intVideo, setIntVideo] = useState(null);
  const [indVideo, setIndVideo] = useState(null);
  const [loading, setLoading] = useState(false);

  const cloudinary = new Cloudinary({
    cloud_name: "djycykgy6",
    api_key: "314356329754634",
    api_secret: "zLpJbOogxu46H8ovnYHg5gcEi0I",
  });

  async function uploadVideo(file) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "atlantis");

    try {
      const res = await axios.post(
        `https://api.cloudinary.com/v1_1/${
          cloudinary.config().cloud_name
        }/video/upload`,
        formData
      );
      return res.data.secure_url;
    } catch (error) {
      console.log(error);
      message.error("Video upload failed");
      return null;
    }
  }

  async function handleSubmit() {
    try {
      setLoading(true);

      let heroVideoUrl = "";
      let intVideoUrl = "";
      let indVideoUrl = "";

      // Upload hero video
      if (heroVideo) {
        heroVideoUrl = await uploadVideo(heroVideo);
        if (!heroVideoUrl) return; // Exit if upload fails
      }

      // Upload international video
      if (intVideo) {
        intVideoUrl = await uploadVideo(intVideo);
        if (!intVideoUrl) return;
      }

      // Upload Indian video
      if (indVideo) {
        indVideoUrl = await uploadVideo(indVideo);
        if (!indVideoUrl) return;
      }

      // Prepare form data to be sent to backend
      const formData = {
        heroVideo: heroVideoUrl,
        intVideo: intVideoUrl,
        indVideo: indVideoUrl,
      };

      const res = await axios.post("/api/hero/add", formData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (res.data.success) {
        message.success(res.data.message);
        setLoading(false);
      } else {
        message.error(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  return (
    <AdminLayout>
      <div className="admin-explore admin-event">
        <div className="page-title">
          <h3 className="m-0">Event Page</h3>
        </div>
        <hr />
        <div className="explore-form">
          <div className="form-fields">
            <small>Hero Video</small>
            <input
              onChange={(e) => setHeroVideo(e.target.files[0])} // Set the file to state
              type="file"
              className="form-control"
              name="heroVideo"
              accept="video/*"
            />
          </div>
          <div className="form-fields">
            <small>International Video</small>
            <input
              onChange={(e) => setIntVideo(e.target.files[0])}
              type="file"
              className="form-control"
              name="intVideo"
              accept="video/*"
            />
          </div>
          <div className="form-fields">
            <small>Indian Video</small>
            <input
              onChange={(e) => setIndVideo(e.target.files[0])}
              type="file"
              className="form-control"
              name="indVideo"
              accept="video/*"
            />
          </div>
          <button
            disabled={loading}
            onClick={handleSubmit}
            className="squarebtn"
          >
            Update
            {loading && (
              <div class="spinner-grow spinner-grow-sm" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            )}
          </button>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminHeroSection;
