import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import DeleteIcon from "@mui/icons-material/Delete";
import "./Packages.css";

const Package = ({ form, setForm }) => {
  const [selectedPackageId, setSelectedPackageId] = useState("");
  const [newPackageGroup, setNewPackageGroup] = useState({ name: "" });
  const [newPackage, setNewPackage] = useState({
    title: "",
    image: null,
    imagePreviewUrl: "",
    time: "",
    location: "",
  });

  const handlePackageGroupChange = (e) => {
    const { name, value } = e.target;
    setNewPackageGroup((prev) => ({ ...prev, [name]: value }));
  };

  const handlePackageChange = (e) => {
    const { name, value } = e.target;
    setNewPackage((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const imagePreviewUrl = URL.createObjectURL(file);
    setNewPackage((prev) => ({ ...prev, image: file, imagePreviewUrl }));
  };

  const handleAddPackageGroup = () => {
    setForm((prevForm) => ({
      ...prevForm,
      packages: [
        ...prevForm.packages,
        {
          id: uuidv4(),
          name: newPackageGroup.name,
          packageArray: [],
        },
      ],
    }));
    setNewPackageGroup({ name: "" });
  };

  const deletePackageGroup = (val) => {
    const updatedPackages = form?.packages.filter((item) => item?.name !== val);
    setForm((prevForm) => ({
      ...prevForm,
      packages: updatedPackages,
    }));
    setNewPackageGroup({ name: "" });
  };

  const handleAddPackage = () => {
    const formData = new FormData();
    formData.append("title", newPackage.title);
    formData.append("time", newPackage.time);
    formData.append("location", newPackage.location);
    if (newPackage.image) {
      formData.append("image", newPackage.image); // Append file
    }

    setForm((prevForm) => ({
      ...prevForm,
      packages: prevForm.packages.map((pkg) => {
        if (pkg.id === selectedPackageId) {
          return {
            ...pkg,
            packageArray: [
              ...pkg.packageArray,
              {
                packageId: uuidv4(),
                ...newPackage,
              },
            ],
          };
        }
        return pkg;
      }),
    }));

    // Reset the package form
    setNewPackage({
      title: "",
      image: null,
      imagePreviewUrl: "", // Reset the preview
      time: "",
      location: "",
    });
  };

  const handlePackageSelectChange = (e) => {
    setSelectedPackageId(e.target.value);
  };

  const handleRemovePackage = (pkgId) => {
    setForm((prevForm) => ({
      ...prevForm,
      packages: prevForm.packages.map((pkg) => {
        if (pkg.id === selectedPackageId) {
          return {
            ...pkg,
            packageArray: pkg.packageArray.filter((p) => p.packageId !== pkgId),
          };
        }
        return pkg;
      }),
    }));
  };

  return (
    <div className="form-container package-container">
      <div className="add-package-group">
        <h3>Add New Package Group</h3>
        <div className="form-fields mb-3">
          <input
            type="text"
            name="name"
            value={newPackageGroup.name}
            onChange={handlePackageGroupChange}
            className="form-control"
            placeholder="Enter package group name"
          />
        </div>
        <button onClick={handleAddPackageGroup} className="btn btn-primary">
          Add Package Group
        </button>
      </div>
      <hr />
      <div className="packagenamelist">
        <h5>Package Group Names</h5>
        {form?.packages?.map((item, index) => {
          return (
            <div className="packname">
              {item?.name}{" "}
              <DeleteIcon
                className="icon"
                onClick={() => deletePackageGroup(item?.name)}
              />
            </div>
          );
        })}
      </div>
      <hr />
      <div className="select-package">
        <select
          className="form-select"
          onChange={handlePackageSelectChange}
          value={selectedPackageId}
        >
          <option value="">Select a package group</option>
          {form.packages.map((pkg) => (
            <option key={pkg.id} value={pkg.id}>
              {pkg.name}
            </option>
          ))}
        </select>
      </div>
      <hr />
      {selectedPackageId && (
        <>
          <div className="add-package">
            <h5>Add Package</h5>
            <div className="form-fields mb-3">
              <input
                type="text"
                name="title"
                value={newPackage.title}
                onChange={handlePackageChange}
                className="form-control"
                placeholder="Enter title"
              />
            </div>
            <div className="form-fields mb-3">
              <input
                type="file"
                name="image"
                onChange={handleFileChange}
                className="form-control"
              />
            </div>
            <div className="form-fields mb-3">
              <input
                type="text"
                name="time"
                value={newPackage.time}
                onChange={handlePackageChange}
                className="form-control"
                placeholder="Enter Days - 7N/8D"
              />
            </div>
            <div className="form-fields mb-3">
              <input
                type="text"
                name="location"
                value={newPackage.location}
                onChange={handlePackageChange}
                className="form-control"
                placeholder="Enter location"
              />
            </div>
            <button onClick={handleAddPackage} className="btn btn-success m-0">
              Add Package
            </button>
          </div>
          <hr />
          <div className="packages">
            <h4>Packages List</h4>
            {form.packages
              .find((pkg) => pkg.id === selectedPackageId)
              ?.packageArray.map((pkg) => (
                <div key={pkg.packageId} className="package-item">
                  <img
                    src={pkg.imagePreviewUrl || pkg?.image}
                    alt="Package Preview"
                  />
                  <p>
                    <strong>Title:</strong> {pkg.title}
                  </p>
                  <p>
                    <strong>Time:</strong> {pkg.time}
                  </p>
                  <p>
                    <strong>Location:</strong> {pkg.location}
                  </p>
                  <button
                    onClick={() => handleRemovePackage(pkg.packageId)}
                    className="btn btn-danger"
                  >
                    Remove
                  </button>
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Package;
