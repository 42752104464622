import React, { useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import "./HeroSection.css";

const HeroSection = ({ videos }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.5;
    }
  }, []);

  return (
    <div className="hero-container">
      <div className="overlay">
        <div className="hero-title">
          <div className="cursive">
            <h3 className="m-0 ">Unleash the Unique in Every Expedition</h3>
          </div>
          <span>
            "Creating eco-friendly and memorable journeys for the most
            discerning explorers."
          </span>
        </div>
        <video
          playsInline
          poster
          autoPlay
          muted
          loop
          src={videos && videos?.heroVideo}
        ></video>
      </div>
    </div>
  );
};

export default HeroSection;
