import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import EventPage from "./pages/EventPage.js";
// import Destinations from "./pages/Destinations";
// import Service from "./pages/Service";
// import Experiences from "./pages/Experiences";
import DestinationPage from "./pages/DestinationPage.js";
import Search from "./pages/Search";
import FrequentlyAskedQuestions from "./pages/FrequentlyAskedQuestions";
import Category from "./pages/Category.js";

import Login from "./pages/Login";
import ForgotPass from "./pages/ForgotPass";
import ResetPassword from "./pages/ResetPassword";

import Terms from "./pages/Terms";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import RefundPolicy from "./pages/RefundPolicy";

import ProtectedRoute from "./components/ProtectedRoute";
import PublicRoute from "./components/PublicRoute";

// === ADMIN
import AdminDashboard from "./admin/AdminDashboard.js";
import AdminLocations from "./admin/AdminLocations.js";
import AdminAddEditLocations from "./admin/AdminAddEditLocations.js";
import AdminQueries from "./admin/AdminQueries";
import AdminVideos from "./admin/AdminVideos.js";
import AdminTestimonials from "./admin/AdminTestimonials.js";
import AdminAddress from "./admin/AdminAddress.js";
import AdminCategory from "./admin/AdminCategory.js";
import AdminPromoBanners from "./admin/AdminPromoBanners.js";
import AdminExplore from "./admin/AdminExplore.js";
import AdminEventPage from "./admin/AdminEventPage.js";
import AdminHeroSection from "./admin/AdminHeroSection.js";
// import Loader from "./pages/Loader";
// import Maintenance from "./pages/Maintenance";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/event/:name?" element={<EventPage />} />
        <Route path="/category/:name?" element={<Category />} />
        {/* <Route path="/services" element={<Service />} /> */}
        {/* <Route path="/destinations" element={<Destinations />} /> */}
        {/* <Route path="/experiences" element={<Experiences />} /> */}
        <Route path="/destination/:name?" element={<DestinationPage />} />
        <Route path="/search" element={<Search />} />
        <Route path="/faq" element={<FrequentlyAskedQuestions />} />
        <Route path="/forgot-password" element={<ForgotPass />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        {/* <Route path="/refund-policy" element={<RefundPolicy />} /> */}
        {/* =================== ADMIN */}
        <Route
          path="/admin-dashboard"
          element={
            <ProtectedRoute>
              <AdminDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-hero"
          element={
            <ProtectedRoute>
              <AdminHeroSection />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-locations"
          element={
            <ProtectedRoute>
              <AdminLocations />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-add-edit-locations/:id?"
          element={
            <ProtectedRoute>
              <AdminAddEditLocations />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-enquiries"
          element={
            <ProtectedRoute>
              <AdminQueries />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-videos"
          element={
            <ProtectedRoute>
              <AdminVideos />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-testimonials"
          element={
            <ProtectedRoute>
              <AdminTestimonials />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-address"
          element={
            <ProtectedRoute>
              <AdminAddress />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-category"
          element={
            <ProtectedRoute>
              <AdminCategory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-promo-banners"
          element={
            <ProtectedRoute>
              <AdminPromoBanners />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-explore"
          element={
            <ProtectedRoute>
              <AdminExplore />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-event"
          element={
            <ProtectedRoute>
              <AdminEventPage />
            </ProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
