import React, { useEffect, useRef, useState } from "react";
import { message } from "antd";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import axios from "axios";
import Slider from "react-slick";
import "./HomeVideos.css";

const HomeVideos = () => {
  const arrowRef = useRef();
  const [videos, setVideos] = useState([null, null, null, null]);

  const fetchVideos = async () => {
    try {
      const response = await axios.get("/api/videos/get-videos");
      setVideos(response.data);
    } catch (error) {
      message.error("Failed to fetch videos");
    }
  };

  useEffect(() => {
    fetchVideos();
  }, []);

  var settings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    speed: 500,
    nextArrow: false,
    prevArrow: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          dots: true,
        },
      },
      {
        breakpoint: 400,
        settings: {
          dots: true,
        },
      },
    ],
  };

  return (
    <div className="home-videos text-center">
      <div className="cursive">
        Our <span className="cursive">Memories</span>
      </div>
      <div className="h-videos">
        <Slider ref={arrowRef} {...settings}>
          {videos.map((item, index) => {
            return (
              <div
                key={index}
                className="youtube-videos col-12 col-sm-12 col-md-6 col-lg-6"
              >
                <iframe
                  src={item}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>
            );
          })}
        </Slider>
        <div className="youtube-btns">
          <div onClick={() => arrowRef.current.slickPrev()}>
            <KeyboardArrowLeftIcon className="icon left" />
          </div>
          <div onClick={() => arrowRef.current.slickNext()}>
            <KeyboardArrowRightIcon className="icon right" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeVideos;
