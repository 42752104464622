import React, { useEffect, useRef, useState } from "react";
import { message } from "antd";
import { Cloudinary } from "cloudinary-core";
import AdminLayout from "./components/AdminLayout";
import axios from "axios";
import "./AdminEventPage.css";

const AdminEventPage = () => {
  const backgroundRef = useRef();
  const backgroundBottomRef = useRef();
  const midsectionRef = useRef();
  const [explore, setExplore] = useState({
    background: "",
    videoLink: "",
    backgroundBottom: "",
    midsection: "",
    name: "",
    description: "",
    fromDate: "",
    toDate: "",
    display: "",
  });

  const cloudinary = new Cloudinary({
    cloud_name: "djycykgy6",
    api_key: "314356329754634",
    api_secret: "zLpJbOogxu46H8ovnYHg5gcEi0I",
  });

  async function uploadVideo(file) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "atlantis");

    try {
      const res = await axios.post(
        `https://api.cloudinary.com/v1_1/${
          cloudinary.config().cloud_name
        }/video/upload`,
        formData
      );
      return res.data.secure_url;
    } catch (error) {
      console.log(error);
      message.error("Video upload failed");
      return null;
    }
  }

  function handleChange(e) {
    const { name, value, files } = e.target;
    if (files) {
      setExplore({ ...explore, [name]: files[0] });
    } else {
      setExplore({ ...explore, [name]: value });
    }
  }

  const formatDate = (date) => {
    if (!date) return "";
    return new Date(date).toISOString().split("T")[0];
  };

  async function handleSubmit() {
    if (!explore.name) {
      return message.error("Enter Name");
    }
    try {
      let videoLink = explore.videoLink;

      // Upload video if it's a file
      if (explore.videoLink && typeof explore.videoLink !== "string") {
        videoLink = await uploadVideo(explore.videoLink, "video");
        if (!videoLink) return;
      }

      const formData = new FormData();
      formData.append("name", explore.name);
      formData.append("description", explore.description);
      formData.append("fromDate", explore.fromDate);
      formData.append("toDate", explore.toDate);
      formData.append("background", explore.background);
      formData.append("videoLink", videoLink);
      formData.append("backgroundBottom", explore.backgroundBottom);
      formData.append("midsection", explore.midsection);
      formData.append("display", explore.display);

      const res = await axios.post("/api/event/addupdate", formData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        setExplore({
          background: "",
          backgroundBottom: "",
          midsection: "",
          name: "",
          description: "",
          fromDate: "",
          toDate: "",
          location: "",
        });
        getExplore();
        if (backgroundRef.current) backgroundRef.current.value = "";
        if (backgroundBottomRef.current) backgroundBottomRef.current.value = "";
        if (midsectionRef.current) midsectionRef.current.value = "";
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getExplore = async () => {
    try {
      const res = await axios.get("/api/event/get-events");
      if (res.data.success) {
        setExplore({
          ...res.data.data,
          fromDate: formatDate(res.data.data.fromDate),
          toDate: formatDate(res.data.data.toDate),
        });
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getExplore();
  }, []);

  return (
    <AdminLayout>
      <div className="admin-explore admin-event">
        <div className="page-title">
          <h3 className="m-0">Event Page</h3>
        </div>
        <hr />
        <div className="explore-form">
          <div className="form-fields">
            <small>Video Link</small>
            <input
              ref={backgroundRef}
              onChange={handleChange}
              type="file"
              className="form-control"
              name="videoLink"
            />
          </div>
          <img src={explore?.backgroundBottom} alt="" />
          <div className="form-fields">
            <small>Background Bottom Image</small>
            <input
              ref={backgroundBottomRef}
              onChange={handleChange}
              type="file"
              className="form-control"
              name="backgroundBottom"
            />
          </div>
          <img src={explore?.midsection} alt="" />
          <div className="form-fields">
            <small>Midsection Image</small>
            <input
              ref={midsectionRef}
              onChange={handleChange}
              type="file"
              className="form-control"
              name="midsection"
            />
          </div>
          <div className="form-fields">
            <small>Enter Name</small>
            <input
              value={explore?.name}
              onChange={handleChange}
              type="text"
              className="form-control"
              name="name"
            />
          </div>
          <div className="form-fields">
            <small>Enter Description</small>
            <input
              value={explore?.description}
              onChange={handleChange}
              type="text"
              className="form-control"
              name="description"
            />
          </div>
          <div className="form-fields">
            <small>From Date</small>
            <input
              value={explore?.fromDate}
              onChange={handleChange}
              type="date"
              className="form-control"
              name="fromDate"
            />
          </div>
          <div className="form-fields">
            <small>To Date</small>
            <input
              value={explore?.toDate}
              onChange={handleChange}
              type="date"
              className="form-control"
              name="toDate"
            />
          </div>
          <div className="form-fields">
            <small>Display</small>
            <select
              value={explore.display}
              onChange={handleChange}
              name="display"
              className="form-control"
            >
              <option value="">Select</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>

          <button onClick={handleSubmit} className="squarebtn">
            Update
          </button>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminEventPage;
