import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import axios from "axios";
import { message, Table, Input, Button } from "antd";
import "./AdminPromoBanners.css";

const AdminPromoBanners = () => {
  const [file, setFile] = useState(null);
  const [fileTwo, setFileTwo] = useState(null);
  const [fileThree, setFileThree] = useState(null);
  const [banners, setBanners] = useState(null);
  const [link, setLink] = useState("");

  function handleChange(e, num) {
    if (num === "one") {
      const file = e.target.files[0];
      setFile(file);
    } else if (num === "two") {
      const file = e.target.files[0];
      setFileTwo(file);
    } else if (num === "three") {
      const file = e.target.files[0];
      setFileThree(file);
    }
  }
  async function handleUpdate(e) {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("banner", file);
      formData.append("bannerTwo", fileTwo);
      formData.append("midSection", fileThree);
      formData.append("midSectionLink", link);

      const res = await axios.post("/api/admin/add-banner", formData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        getBanners();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function getBanners() {
    try {
      const res = await axios.get("/api/admin/get-banner");
      if (res.data.success) {
        setBanners(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getBanners();
  }, []);

  return (
    <AdminLayout>
      <div className="admin-promo-banner">
        <div className="page-title">
          <h3 className="m-0">Promo Banners</h3>
        </div>
        <hr />
        <div className="banner-container">
          <h6>First Banner</h6>
          <img src={banners?.banner} alt="img" />
          <small>First Banner</small>
          <input
            onChange={(e) => handleChange(e, "one")}
            type="file"
            className="form-control"
          />
          <span className="text-danger">
            <small>Image size = 2000 x 266</small>
          </span>
        </div>
        <hr />
        <div className="banner-container">
          <h6>Contact Banner</h6>
          <img src={banners?.bannerTwo} alt="img" />
          <small>Second Banner</small>
          <input
            onChange={(e) => handleChange(e, "two")}
            type="file"
            className="form-control"
          />
          <span className="text-danger">
            <small>Image size = 2000 x 542</small>
          </span>
        </div>
        <hr />
        <div className="banner-container">
          <h6>Mid Section Banner</h6>
          <img src={banners?.midSection} alt="img" />
          <small>Mid Section Image</small>
          <input
            onChange={(e) => handleChange(e, "three")}
            type="file"
            className="form-control"
          />
          <span className="text-danger">
            <small>Image size = 1366 x 400</small>
          </span>
        </div>
        <div className="banner-container">
          <small>Mid Section Link</small>
          <input
            onChange={(e) => setLink(e.target.value)}
            className="form-control"
            type="text"
            value={banners?.midSectionLink}
          />
        </div>
        <button onClick={handleUpdate}>Update</button>
      </div>
    </AdminLayout>
  );
};

export default AdminPromoBanners;
