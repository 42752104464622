import React, { useEffect, useState } from "react";
import { message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import HeroSection from "../components/Home/HeroSection";
import OurRatings from "../components/Home/OurRatings.js";
import PromoBanner from "../components/Home/PromoBanner.js";
import InternationalPackages from "../components/Home/InternationalPackages.js";
import IndianPackages from "../components/Home/IndianPackages.js";
import MobilePackages from "../components/Home/MobilePackages.js";
import Explore from "../components/Home/Explore.js";
import HomeVideos from "../components/Home/HomeVideos.js";
import WhyUs from "../components/Home/WhyUs.js";
import axios from "axios";
import ContactForm from "../components/ContactForm.js";
import Testimonials from "../components/Testimonials.js";
import "./Home.css";

const Home = () => {
  const navigate = useNavigate();
  const [banners, setBanners] = useState(null);
  const [locations, setLocations] = useState(null);
  const [data, setData] = useState(null);
  const [videos, setVideos] = useState(null);

  async function getBanners() {
    try {
      const res = await axios.get("/api/admin/get-banner");
      if (res.data.success) {
        setBanners(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function getLocations() {
    try {
      const res = await axios.get("/api/admin/get-locations");
      if (res.data.success) {
        setLocations(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function getExplore() {
    try {
      const res = await axios.get("/api/admin/get-explore");
      if (res.data.success) {
        setData(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function getVideos() {
    try {
      const res = await axios.get("/api/hero/get");
      if (res.data.success) {
        setVideos(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getLocations();
    getBanners();
    getExplore();
    getVideos();
  }, []);

  return (
    <Layout>
      <HeroSection videos={videos} />
      <OurRatings />
      <PromoBanner banners={banners} />
      <div className="d-block d-mg-block d-lg-none">
        <MobilePackages locations={locations} />
      </div>
      <div className="d-none d-lg-block">
        <InternationalPackages videos={videos} locations={locations} />
      </div>
      <div className="d-none d-lg-block mt-5">
        <IndianPackages videos={videos} locations={locations} />
      </div>
      <div className="mid-section">
        <Link to={banners?.midSectionLink} target="_blank">
          <img src={banners?.midSection} alt="" />
        </Link>
        <Explore data={data} />
      </div>
      <div className="mid-section contactbanner">
        <div className="content">
          <h4>Dreaming of your next Adventure?</h4>
          <p>Connect with us!</p>
          <button className="squarebtn" onClick={() => navigate("/contact")}>
            Contact Us
          </button>
        </div>
        <img src={banners?.bannerTwo} alt="" />
      </div>
      <Testimonials />
      <WhyUs />
      <HomeVideos />
      <ContactForm />
    </Layout>
  );
};

export default Home;
