import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { message } from "antd";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import axios from "axios";
import "./SideMenu.css";

const SideMenu = ({ sideMenu, setSideMenu }) => {
  const { category } = useSelector((state) => state.category);
  const { event } = useSelector((state) => state.event);
  const navigate = useNavigate();
  const [form, setForm] = useState(null);

  const fetchAddress = async () => {
    try {
      const res = await axios.get("/api/admin/get-address");
      if (res.data.success) {
        setForm(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAddress();
  }, []);

  function clearSpace(value) {
    return form && value.replace(/\s+/g, "");
  }

  return (
    <div className={`sidemenu-container ${sideMenu ? "active" : ""}`}>
      <div className="sidemenu">
        <HighlightOffIcon
          onClick={() => setSideMenu(!sideMenu)}
          className="close-icon"
        />
        <ul>
          {category &&
            category?.map((item, index) => {
              return (
                <li
                  key={index}
                  onClick={() =>
                    navigate(`/category/${item?.category.replace(" ", "-")}`)
                  }
                >
                  {item?.category}
                </li>
              );
            })}
          <li
            onClick={() => {
              navigate("/about");
              setSideMenu(!sideMenu);
            }}
          >
            About Us
          </li>
          {event?.display === "yes" && (
            <li
              onClick={() => {
                navigate("/event");
                setSideMenu(!sideMenu);
              }}
            >
              {event?.name}
            </li>
          )}
        </ul>
        <button
          className="contactbtn"
          onClick={() => {
            navigate("/contact");
            setSideMenu(!sideMenu);
          }}
        >
          Contact Us
        </button>
        <div className="bottom-social">
          <Link
            target="_blank"
            to="https://www.instagram.com/fourseasonholiday"
          >
            <InstagramIcon className="icon" />
          </Link>
          <Link
            target="_blank"
            to="https://www.facebook.com/profile.php?id=100082222682685&mibextid=LQQJ4d "
          >
            <FacebookIcon className="icon" />
          </Link>
          <Link
            target="_blank"
            to={`https://wa.me/${clearSpace(form?.mobile)}`}
          >
            <WhatsAppIcon className="icon" />
          </Link>
          <Link
            target="_blank"
            to="mailto:travelwithexoticindiavacations@gmail.com"
          >
            <EmailIcon className="icon" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
