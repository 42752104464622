import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import axios from "axios";
import { message, Table, Input, Button } from "antd";
import "./AdminQueries.css";

const AdminVideos = () => {
  const [videos, setVideos] = useState([null, null, null, null]);
  const [loading, setLoading] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [newLink, setNewLink] = useState("");
  const [heroLink, setHeroLink] = useState("");

  async function handleUpdateHeroLink() {
    try {
      const res = await axios.post(
        "/api/videos/update-hero-link",
        {
          link: heroLink,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchVideos();
  }, []);

  const fetchVideos = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/api/videos/get-videos");
      setVideos(response.data);
    } catch (error) {
      message.error("Failed to fetch videos");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateVideo = async () => {
    if (editingIndex === null || newLink === "") {
      message.error("Please select a video to edit and provide a new link.");
      return;
    }
    try {
      await axios.post(
        `/api/videos/update-video`,
        {
          link: newLink,
          index: editingIndex,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      message.success("Video link updated successfully.");
      fetchVideos();
      setEditingIndex(null);
      setNewLink("");
    } catch (error) {
      message.error("Failed to update video link.");
    }
  };

  const columns = [
    {
      title: "Sr No",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Video Link",
      dataIndex: "link",
      key: "link",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record, index) => (
        <Button onClick={() => setEditingIndex(index)}>Edit</Button>
      ),
    },
  ];

  const dataSource = videos.map((link, index) => ({ index, link }));

  return (
    <AdminLayout>
      <div className="page-title">
        <h3 className="m-0">Our Memories Videos</h3>
      </div>
      <hr />
      <Table
        columns={columns}
        dataSource={dataSource}
        rowKey="index"
        loading={loading}
        pagination={false}
      />
      {editingIndex !== null && (
        <div className="edit-section">
          <Input
            placeholder="Enter new video link"
            value={newLink}
            onChange={(e) => setNewLink(e.target.value)}
          />
          <Button onClick={handleUpdateVideo}>Update Video</Button>
        </div>
      )}
      {/* <hr />
      <label className="mt-4 mb-2" htmlFor="">
        Hero Section Video
      </label>
      <div className="d-flex form-fields mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Enter Youtube Link"
          onChange={(e) => setHeroLink(e.target.value)}
          name="heroLink"
        />
        <button onClick={handleUpdateHeroLink} className="admin-btn px-3">
          Update
        </button>
      </div> */}
    </AdminLayout>
  );
};

export default AdminVideos;
