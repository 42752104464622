import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import DeleteIcon from "@mui/icons-material/Delete";
import "./Packages.css";

const Activity = ({ form, setForm }) => {
  const [selectedActivityId, setSelectedActivityId] = useState("");
  const [newActivityGroup, setNewActivityGroup] = useState({
    name: "",
    description: "",
  });
  const [newActivity, setNewActivity] = useState({
    title: "",
    image: null,
    imagePreviewUrl: "",
    time: "",
    description: "",
    location: "",
  });

  const handleActivityGroupChange = (e) => {
    const { name, value } = e.target;
    setNewActivityGroup((prev) => ({ ...prev, [name]: value }));
  };

  const handleActivityChange = (e) => {
    const { name, value } = e.target;
    setNewActivity((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const imagePreviewUrl = URL.createObjectURL(file);
    setNewActivity((prev) => ({ ...prev, image: file, imagePreviewUrl }));
  };

  const handleAddActivityGroup = () => {
    setForm((prevForm) => ({
      ...prevForm,
      activities: [
        ...prevForm.activities,
        {
          id: uuidv4(),
          name: newActivityGroup.name,
          description: newActivityGroup.description,
          activityArray: [],
        },
      ],
    }));
    setNewActivityGroup({ name: "", description: "" });
  };

  const deleteActivityGroup = (val) => {
    const updatedActivities = form?.activities.filter(
      (item) => item?.name !== val
    );
    setForm((prevForm) => ({
      ...prevForm,
      activities: updatedActivities,
    }));
    setNewActivityGroup({ name: "" });
  };

  const generateUniqueId = () => {
    return Date.now().toString(36) + Math.random().toString(36).substr(2, 9);
  };

  const handleAddActivity = () => {
    const newId = generateUniqueId();
    const formData = new FormData();
    formData.append("title", newActivity.title);
    formData.append("description", newActivity.title);
    formData.append("time", newActivity.time);
    formData.append("location", newActivity.location);
    if (newActivity.image) {
      formData.append("image", newActivity.image);
    }

    setForm((prevForm) => ({
      ...prevForm,
      activities: prevForm.activities.map((act) => {
        if (act.id === selectedActivityId) {
          return {
            ...act,
            activityArray: [
              ...act.activityArray,
              {
                activityId: newId,
                title: newActivity.title,
                description: newActivity.description,
                image: newActivity.image,
                imagePreviewUrl: newActivity.imagePreviewUrl,
                time: newActivity.time,
                location: newActivity.location,
              },
            ],
          };
        }
        return act;
      }),
    }));

    // Reset the package form
    setNewActivity({
      title: "",
      image: null,
      description: "",
      time: "",
      location: "",
    });
  };

  const handleActivitySelectChange = (e) => {
    setSelectedActivityId(e.target.value);
  };

  const handleRemoveActivity = (actId) => {
    setForm((prevForm) => ({
      ...prevForm,
      activities: prevForm.activities.map((act) => {
        if (act.id === selectedActivityId) {
          return {
            ...act,
            activityArray: act.activityArray.filter(
              (activity) => activity._id !== actId
            ),
          };
        }
        return act;
      }),
    }));
  };

  return (
    <div className="form-container package-container">
      <div className="add-package-group">
        <h3>Add New Activity Group</h3>
        <div className="form-fields mb-3">
          <input
            type="text"
            name="name"
            value={newActivityGroup.name}
            onChange={handleActivityGroupChange}
            className="form-control"
            placeholder="Enter activity group name"
          />
          <textarea
            name="description"
            onChange={handleActivityGroupChange}
            value={newActivityGroup.description}
            className="form-control"
            placeholder="Enter activity description"
          ></textarea>
        </div>
        <button onClick={handleAddActivityGroup} className="btn btn-primary">
          Add Activity Group
        </button>
      </div>
      <hr />
      <div className="packagenamelist">
        <h5>Activitiy Group Names</h5>
        {form?.activities?.map((item, index) => {
          return (
            <div className="packname">
              {item?.name}{" "}
              <DeleteIcon
                className="icon"
                onClick={() => deleteActivityGroup(item?.name)}
              />
            </div>
          );
        })}
      </div>
      <hr />
      <div className="select-package">
        <h5>Select Activity</h5>
        <select
          className="form-select"
          onChange={handleActivitySelectChange}
          value={selectedActivityId}
        >
          <option value="">Select a activity group</option>
          {form.activities.map((act) => (
            <option key={act.id} value={act.id}>
              {act.name}
            </option>
          ))}
        </select>
      </div>
      <hr />
      {selectedActivityId && (
        <>
          <div className="add-package">
            <h5>Add Activity</h5>
            <div className="form-fields mb-3">
              <input
                type="text"
                name="title"
                value={newActivity.title}
                onChange={handleActivityChange}
                className="form-control"
                placeholder="Enter title"
              />
            </div>
            <textarea
              name="description"
              value={newActivity.description}
              onChange={handleActivityChange}
              className="form-control"
              placeholder="Enter description"
            ></textarea>
            <div className="form-fields mb-3">
              <input
                type="file"
                name="image"
                onChange={handleFileChange}
                className="form-control"
              />
            </div>
            <div className="form-fields mb-3">
              <input
                type="text"
                name="time"
                value={newActivity.time}
                onChange={handleActivityChange}
                className="form-control"
                placeholder="Enter time"
              />
            </div>
            <div className="form-fields mb-3">
              <input
                type="text"
                name="location"
                value={newActivity.location}
                onChange={handleActivityChange}
                className="form-control"
                placeholder="Enter location"
              />
            </div>
            <button onClick={handleAddActivity} className="btn btn-success">
              Add Activity
            </button>
          </div>
          <hr />
          <div className="packages">
            <h4>Packages List</h4>
            {form.activities
              .find((act) => act.id === selectedActivityId)
              ?.activityArray.map((act) => (
                <div key={act.activityId} className="package-item">
                  <img
                    src={act?.imagePreviewUrl || act?.image}
                    alt="Package Preview"
                  />
                  <p>
                    <strong>Title:</strong> {act.title}
                  </p>
                  <p>
                    <strong>Desc:</strong> <small>{act.description}</small>
                  </p>
                  <p>
                    <strong>Time:</strong> {act.time}
                  </p>
                  <p>
                    <strong>Location:</strong> {act.location}
                  </p>
                  <button
                    onClick={() => handleRemoveActivity(act._id)}
                    className="btn btn-danger"
                  >
                    Remove
                  </button>
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Activity;
