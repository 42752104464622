import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { message } from "antd";
import Layout from "../components/Layout/Layout";
import axios from "axios";
import StarIcon from "@mui/icons-material/Star";
import IMAGES from "../img/image.js";
import "./Category.css";

const Category = () => {
  const params = useParams();
  const navigate = useNavigate();
  const category = params?.name.replace("-", " ");
  const [locations, setlocations] = useState(null);
  const [form, setForm] = useState({ name: "", email: "", mobile: "" });
  const [error, setError] = useState(false);
  const [num, setNum] = useState(false);

  const fetchLocation = async () => {
    try {
      const res = await axios.post("/api/location/get-locations", {
        category: category,
      });
      if (res.data.success) {
        setlocations(res.data.data);
        const randomNum = Math.floor(Math.random() * res.data.data.length);
        setNum(randomNum);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchLocation();
  }, [params?.name]);

  function handleChange(e) {
    setForm({ ...form, [e.target.name]: e.target.value });
  }

  const bannerImageUrl = `${
    locations && locations[num]?.bannerImage.replace(/\\/g, "/")
  }`;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (form?.name === "" || form?.email === "" || form?.mobile === "") {
      setError(true);
      return;
    }
    if (isNaN(form.mobile)) {
      return message.error("Please enter only numbers in mobile");
    }
    if (form.mobile.length > 10 || form?.mobile.length < 10) {
      return message.error("Enter 10 digits in mobile");
    }
    try {
      const res = await axios.post("/api/contact/add-contact-form", form);
      if (res.data.success) {
        message.success(res.data.message);
        setForm({ name: "", email: "", mobile: "", msg: "" });
        setError(false);
      } else {
        message.error(res.data.message);
        setError(false);
      }
    } catch (error) {
      setError(false);
      console.log(error);
    }
  };

  return (
    <Layout>
      <div
        className="dest-container"
        style={{
          background: `url(${bannerImageUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="bg-overlay">
          <div className="formandtitle">
            <div className="title">
              <h4>{category}</h4>
              <p>
                Journey Through Time with Atlantis: Your Gateway to Global
                Exploration
              </p>
            </div>
            <div className="cform d-none d-lg-block">
              <p>
                Not sure what to do? We'll give you a <span>Call Back</span>
              </p>
              <div className="form-fields">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Enter your name"
                  onChange={handleChange}
                  value={form?.name}
                />
                {error && form?.name === "" && (
                  <span className="text-start text-danger d-block">
                    <small>Enter name</small>
                  </span>
                )}
              </div>
              <div className="form-fields">
                <label>Email</label>
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  placeholder="Enter your email"
                  onChange={handleChange}
                  value={form?.email}
                />
                {error && form?.email === "" && (
                  <span className="text-start text-danger d-block">
                    <small>Enter email</small>
                  </span>
                )}
              </div>
              <div className="form-fields">
                <label>Mobile</label>
                <input
                  type="text"
                  className="form-control"
                  name="mobile"
                  placeholder="Enter your phone"
                  onChange={handleChange}
                  value={form?.mobile}
                />
                {error && form?.mobile === "" && (
                  <span className="text-start text-danger d-block">
                    <small>Enter mobile</small>
                  </span>
                )}
              </div>
              <button onClick={handleSubmit}>Submit</button>
            </div>
            <div className="reviews">
              <div className="triprating">
                <div className="img">
                  <img src={IMAGES.google} alt="" />
                </div>
                <div className="content">
                  <StarIcon className="icon" />
                  <span>4.9</span>
                  <p>(10870 Reviews)</p>
                </div>
              </div>
              <div className="triprating">
                <div className="img">
                  <img src={IMAGES.facebook} alt="" />
                </div>
                <div className="content">
                  <StarIcon className="icon" />
                  <span>5.0</span>
                  <p>(3323 reviews)</p>
                </div>
              </div>
              <div className="triprating">
                <div className="img">
                  <img src={IMAGES.tripadvisor} alt="" />
                </div>
                <div className="content">
                  <StarIcon className="icon" />
                  <span>5.0</span>
                  <p>(1031 reviews)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {locations ? (
        <div className="category">
          <h2>Destinations</h2>
          <div className="alllocations">
            {locations &&
              locations?.map((item, index) => {
                return (
                  <div
                    className="locations"
                    onClick={() => navigate(`/destination/${item?.tourName}`)}
                    data-tourname={item?.tourName}
                  >
                    <img src={item?.tourImage} alt="" />
                  </div>
                );
              })}
          </div>
        </div>
      ) : (
        <div className="nolocation">
          <h4>No Location Found</h4>
          <img src={IMAGES.nolocation} alt="" />
        </div>
      )}
    </Layout>
  );
};

export default Category;
