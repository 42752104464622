import React, { useEffect, useState } from "react";
import Multiselect from "multiselect-react-dropdown";
import axios from "axios";
import { message } from "antd";

const BasicInfo = ({ handleChange, form, setForm }) => {
  const [allCategories, setAllCategories] = useState([]);

  async function getCategory(e) {
    try {
      const res = await axios.get("/api/admin/get-category");
      if (res.data.success) {
        setAllCategories(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getCategory();
  }, []);

  const handleCategoryChange = (selectedList) => {
    setForm((prevForm) => ({
      ...prevForm,
      categories: selectedList,
    }));
  };

  return (
    <div>
      <div className="form-fields mb-4">
        <label className="form-label" htmlFor="name">
          Tour Name
        </label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter tour name"
          name="tourName"
          onChange={handleChange}
          value={form?.tourName}
        />
      </div>
      <div className="form-fields mb-4">
        <label className="form-label" htmlFor="name">
          Name
        </label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter location name"
          name="name"
          onChange={handleChange}
          value={form?.name}
        />
      </div>
      <div className="form-fields mb-4">
        <label className="form-label" htmlFor="name">
          Description
        </label>
        <textarea
          name="description"
          className="form-control"
          placeholder="Enter short description"
          onChange={handleChange}
          value={form?.description}
          rows={5}
        ></textarea>
      </div>
      <div className="form-fields mb-4">
        <label htmlFor="category" className="form-label">
          Category
        </label>
        <Multiselect
          id="category"
          className="multiselect-wrapper"
          isObject={false}
          onRemove={handleCategoryChange}
          onSelect={handleCategoryChange}
          selectedValues={form.categories}
          options={allCategories.map((cat) => cat.category)}
        />
      </div>
      <div className="form-fields mb-4">
        <label htmlFor="overview" className="form-label">
          Overview
        </label>
        <textarea
          rows={10}
          name="overview"
          className="form-control"
          placeholder="Write a description for the location"
          onChange={handleChange}
          value={form?.overview}
        ></textarea>
      </div>
    </div>
  );
};

export default BasicInfo;
