import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SlowMotionVideoIcon from "@mui/icons-material/SlowMotionVideo";
import ReviewsIcon from "@mui/icons-material/Reviews";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import MapIcon from "@mui/icons-material/Map";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import LogoutIcon from "@mui/icons-material/Logout";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import { Link, useNavigate } from "react-router-dom";
import "./AdminSidebar.css";

const AdminSidebar = () => {
  const navigate = useNavigate();

  function handleLogout() {
    localStorage.removeItem("token");
    navigate("/login");
  }

  return (
    <div className="admin-sidebar-container">
      <span>
        <small>MAIN</small>
      </span>
      <ul>
        <li>
          <Link to="/admin-dashboard">
            <HomeIcon className="me-2 icon" />
            Dashboard
          </Link>
        </li>
      </ul>
      <span>
        <small>LISTS</small>
      </span>
      <ul>
        <li>
          <Link to="/admin-locations">
            <LocationOnIcon className="me-2 icon" />
            Locations
          </Link>
        </li>
        <li>
          <Link to="/admin-event">
            <AcUnitIcon className="me-2 icon" />
            Event Page
          </Link>
        </li>
        <li>
          <Link to="/admin-hero">
            <SlowMotionVideoIcon className="me-2 icon" />
            Hero Section
          </Link>
        </li>
        <li>
          <Link to="/admin-testimonials">
            <ReviewsIcon className="me-2 icon" />
            Testimonials
          </Link>
        </li>
        <li>
          <Link to="/admin-promo-banners">
            <ViewCarouselIcon className="me-2 icon" />
            Promo Banners
          </Link>
        </li>
        <li>
          <Link to="/admin-explore">
            <ViewCarouselIcon className="me-2 icon" />
            Explore Slider
          </Link>
        </li>
        <li>
          <Link to="/admin-enquiries">
            <HelpCenterIcon className="me-2 icon" />
            Enquiries
          </Link>
        </li>
        <li>
          <Link to="/admin-videos">
            <SlowMotionVideoIcon className="me-2 icon" />
            Our Memories
          </Link>
        </li>
        <li>
          <Link to="/admin-address">
            <MapIcon className="me-2 icon" />
            Address
          </Link>
        </li>
        <li onClick={handleLogout}>
          <LogoutIcon className="me-2 icon" />
          Logout
        </li>
      </ul>
    </div>
  );
};

export default AdminSidebar;
