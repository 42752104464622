import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import axios from "axios";
import { message, Table, Input, Button, Modal, Form } from "antd";
import "./AdminQueries.css";

const AdminTestimonials = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editingTestimonial, setEditingTestimonial] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    fetchTestimonials();
  }, []);

  const fetchTestimonials = async () => {
    setLoading(true);
    try {
      const response = await axios.get("api/testimonials/get");
      setTestimonials(response.data.data);
    } catch (error) {
      message.error("Failed to fetch testimonials");
    } finally {
      setLoading(false);
    }
  };

  const handleAddTestimonial = async (values) => {
    try {
      await axios.post("/api/testimonials/add-testimonial", values, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      message.success("Testimonial added successfully.");
      fetchTestimonials();
      setIsModalVisible(false);
    } catch (error) {
      message.error("Failed to add testimonial.");
    }
  };

  const handleEditTestimonial = async (id, values) => {
    try {
      await axios.put(`/api/testimonials/${id}`, values, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      message.success("Testimonial updated successfully.");
      fetchTestimonials();
      setEditingTestimonial(null);
      setIsModalVisible(false);
    } catch (error) {
      message.error("Failed to update testimonial.");
    }
  };

  const handleDeleteTestimonial = async (id) => {
    try {
      await axios.delete(`/api/testimonials/${id}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      message.success("Testimonial deleted successfully.");
      fetchTestimonials();
    } catch (error) {
      message.error("Failed to delete testimonial.");
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Review",
      dataIndex: "review",
      key: "review",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <>
          <Button
            onClick={() => {
              setEditingTestimonial(record);
              setIsModalVisible(true);
            }}
          >
            Edit
          </Button>
          <Button
            onClick={() => handleDeleteTestimonial(record._id)}
            style={{ marginLeft: 8 }}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  const handleModalOk = (values) => {
    if (editingTestimonial) {
      handleEditTestimonial(editingTestimonial._id, values);
    } else {
      handleAddTestimonial(values);
    }
  };

  return (
    <AdminLayout>
      <div className="page-title">
        <h3 className="m-0">Testimonials</h3>
      </div>
      <hr />
      <Button type="primary" onClick={() => setIsModalVisible(true)}>
        Add Testimonial
      </Button>
      <Table
        columns={columns}
        dataSource={testimonials}
        rowKey="_id"
        loading={loading}
        pagination={false}
      />
      <Modal
        title={editingTestimonial ? "Edit Testimonial" : "Add Testimonial"}
        visible={isModalVisible}
        onCancel={() => {
          setEditingTestimonial(null);
          setIsModalVisible(false);
        }}
        footer={null}
      >
        <Form initialValues={editingTestimonial} onFinish={handleModalOk}>
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Please input the name!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="review"
            label="Review"
            rules={[{ required: true, message: "Please input the review!" }]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name="location"
            label="Location"
            rules={[{ required: true, message: "Please input the location!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {editingTestimonial ? "Update" : "Add"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </AdminLayout>
  );
};

export default AdminTestimonials;
