import React from "react";
import "./PromoBanner.css";

const PromoBanner = ({ banners }) => {
  console.log(banners);
  return (
    <div className="promo-container">
      <img src={banners?.banner} alt="" />
    </div>
  );
};

export default PromoBanner;
