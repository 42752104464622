import React from "react";

const Usefulinfo = ({ form, handleAddUsefulInfo, handleRemoveUsefulInfo }) => {
  return (
    <div className="form-container">
      <div>
        <div className="form-fields mb-4">
          <label className="form-label" htmlFor="info-title">
            Title
          </label>
          <input
            type="text"
            className="form-control"
            id="info-title"
            placeholder="Enter heading"
          />
        </div>
        <div className="form-fields mb-4">
          <label className="form-label" htmlFor="info-description">
            Description
          </label>
          <textarea
            id="info-description"
            className="form-control"
            rows={5}
            placeholder="Enter description"
          ></textarea>
          <button className="theme-btn mt-4" onClick={handleAddUsefulInfo}>
            Add
          </button>
        </div>
        <table className="table mb-5">
          <thead>
            <tr>
              <th>Heading</th>
              <th>Description</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {form?.usefulInformation?.map((info, index) => (
              <tr key={index}>
                <td>{info.title}</td>
                <td>{info.description}</td>
                <td>
                  <button
                    className="admin-btn bg-danger"
                    onClick={() => handleRemoveUsefulInfo(index)}
                  >
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Usefulinfo;
