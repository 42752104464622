import React, { useRef } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import "./Explore.css";

const Explore = ({ data }) => {
  const arrowRef = useRef();
  const navigate = useNavigate();

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="explore-container">
      <div className="title">
        <h4>Explore</h4>
        <div className="explore-buttons">
          <div onClick={() => arrowRef.current.slickPrev()}>
            <KeyboardArrowLeftIcon className="icon left" />
          </div>
          <div onClick={() => arrowRef.current.slickNext()}>
            <KeyboardArrowRightIcon className="icon right" />
          </div>
        </div>
      </div>
      <div className="explore-locations">
        <Slider ref={arrowRef} {...settings}>
          {data &&
            data.map((item, index) => {
              return (
                <div
                  className="explore-loc"
                  onClick={() => navigate(`/destination/${item?.location}`)}
                >
                  <div className="image">
                    <img
                      src={`https://atlantisholidayz.com/${item?.image}`}
                      alt=""
                    />
                    <div className="circle">{item?.name}</div>
                  </div>
                </div>
              );
            })}
        </Slider>
      </div>
    </div>
  );
};

export default Explore;
