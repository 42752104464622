import React, { useEffect, useState, useRef } from "react";
import Layout from "../components/Layout/Layout";
import { useParams } from "react-router-dom";
import { message } from "antd";
import { Collapse } from "antd";
import axios from "axios";
import StarIcon from "@mui/icons-material/Star";
import IMAGES from "../img/image.js";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import Faq from "../components/Home/Faq.js";
import ContactForm from "../components/ContactForm.js";
import WhyUs from "../components/Home/WhyUs.js";
import Testimonials from "../components/Testimonials.js";
import "./DestinationPage.css";

const DestinationPage = () => {
  const params = useParams();
  const [dest, setDest] = useState(null);
  const [form, setForm] = useState({ name: "", email: "", mobile: "" });
  const [error, setError] = useState(false);

  async function getDestination() {
    try {
      const tourName = params?.name;
      const res = await axios.post("/api/admin/get-location-by-name", {
        name: tourName,
      });
      if (res.data.success) {
        setDest(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function handleChange(e) {
    setForm({ ...form, [e.target.name]: e.target.value });
  }

  useEffect(() => {
    getDestination();
  }, []);

  const bannerImageUrl = `${dest?.bannerImage.replace(/\\/g, "/")}`;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (form?.name === "" || form?.email === "" || form?.mobile === "") {
      setError(true);
      return;
    }
    if (isNaN(form.mobile)) {
      return message.error("Please enter only numbers in mobile");
    }
    if (form.mobile.length > 10 || form?.mobile.length < 10) {
      return message.error("Enter 10 digits in mobile");
    }
    try {
      const res = await axios.post("/api/contact/add-contact-form", form);
      if (res.data.success) {
        message.success(res.data.message);
        setForm({ name: "", email: "", mobile: "", msg: "" });
        setError(false);
      } else {
        message.error(res.data.message);
        setError(false);
      }
    } catch (error) {
      setError(false);
      console.log(error);
    }
  };

  return (
    <Layout>
      <div
        className="dest-container"
        style={{
          background: `url(${bannerImageUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="bg-overlay">
          <div className="formandtitle">
            <div className="title">
              <h4>{dest?.name}</h4>
              <p>{dest?.description}</p>
            </div>
            <div className="cform d-none d-lg-block">
              <p>
                Not sure what to do? We'll give you a <span>Call Back</span>
              </p>
              <div className="form-fields">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Enter your name"
                  onChange={handleChange}
                  value={form?.name}
                />
                {error && form?.name === "" && (
                  <span className="text-start text-danger d-block">
                    <small>Enter name</small>
                  </span>
                )}
              </div>
              <div className="form-fields">
                <label>Email</label>
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  placeholder="Enter your email"
                  onChange={handleChange}
                  value={form?.email}
                />
                {error && form?.email === "" && (
                  <span className="text-start text-danger d-block">
                    <small>Enter email</small>
                  </span>
                )}
              </div>
              <div className="form-fields">
                <label>Mobile</label>
                <input
                  type="text"
                  className="form-control"
                  name="mobile"
                  placeholder="Enter your phone"
                  onChange={handleChange}
                  value={form?.mobile}
                />
                {error && form?.mobile === "" && (
                  <span className="text-start text-danger d-block">
                    <small>Enter mobile</small>
                  </span>
                )}
              </div>
              <button onClick={handleSubmit}>Submit</button>
            </div>
            <div className="reviews">
              <div className="triprating">
                <div className="img">
                  <img src={IMAGES.google} alt="" />
                </div>
                <div className="content">
                  <StarIcon className="icon" />
                  <span>4.9</span>
                  <p>(10870 Reviews)</p>
                </div>
              </div>
              <div className="triprating">
                <div className="img">
                  <img src={IMAGES.facebook} alt="" />
                </div>
                <div className="content">
                  <StarIcon className="icon" />
                  <span>5.0</span>
                  <p>(3323 reviews)</p>
                </div>
              </div>
              <div className="triprating">
                <div className="img">
                  <img src={IMAGES.tripadvisor} alt="" />
                </div>
                <div className="content">
                  <StarIcon className="icon" />
                  <span>5.0</span>
                  <p>(1031 reviews)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="tourdetails">
        {/* ABOUT */}
        <div className="tourabout">
          <h4>About {dest?.name} Tour Package</h4>
          <p>{dest?.overview}</p>
        </div>

        {/* PACKAGES */}
        <div className="tourpackages">
          {dest?.packages?.map((item, index) => {
            return (
              <div className="packagecontainer" key={index}>
                <h4>{item?.name}</h4>
                {item?.packageArray?.map((item, ind) => {
                  return (
                    <div className="package">
                      <img src={item?.image} alt="" />
                      <div className="packcontent">
                        <p>{item?.title}</p>
                        <div className="timelocation">
                          <span>
                            <AccessTimeIcon className="icon me-1" />
                            {item?.time}
                          </span>
                          <span>
                            <FmdGoodIcon className="icon me-1" />
                            {item?.location}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>

        {/* ACTIVITIES */}
        <div className="tourpackages touractivities">
          {dest?.activities?.map((item, index) => {
            return (
              <div className="packagecontainer">
                <h4>{item?.name}</h4>
                {item?.activityArray?.map((item, ind) => {
                  return (
                    <div className="package">
                      <img src={item?.image} alt="" />
                      <div className="packcontent">
                        <p>{item?.title}</p>
                        <div className="description">{item?.description}</div>
                        <div className="timelocation">
                          <span>
                            <AccessTimeIcon className="icon me-1" />
                            {item?.time}
                          </span>
                          <span>
                            <FmdGoodIcon className="icon me-1" />
                            {item?.location}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>

      {/* WHYUS */}
      <Testimonials />

      {/* WHYUS */}
      <WhyUs />

      {/* CONTACT US  */}
      <ContactForm />

      {/* FAQS */}
      <Faq dest={dest} />

      {/* OVERVIEW  */}
      {/* <div ref={overviewRef} className="overview-container text-center">
        <h1 className="hrline">Overview</h1>
        <p>{dest?.overview}</p>
      </div> */}

      {/* GALLERY  */}
      {/* {dest?.gallery?.length !== 0 && (
        <div ref={galleryRef} className="gallery-container">
          <Slider ref={arrowRef} {...settings}>
            {dest?.gallery?.map((item, index) => {
              return <img src={item} alt="" />;
            })}
          </Slider>
          <div className="gallery-slider-btn">
            <div onClick={() => arrowRef.current.slickPrev()}>
              <KeyboardArrowLeftIcon className="icon gla" />
            </div>
            <div onClick={() => arrowRef.current.slickNext()}>
              <KeyboardArrowRightIcon className="icon gra" />
            </div>
          </div>
        </div>
      )} */}

      {/* REGIONS */}
      {/* {dest?.regions?.length !== 0 && (
        <div ref={regionsRef} className="regions-container">
          <Regions dest={dest?.regions} />
        </div>
      )} */}

      {/* USEFUL INFORMATION */}
      {/* {dest?.itinerary?.length > 0 && (
        <div className="single-short-details itinerary">
          <h1 className="text-center">Itinerary</h1>
          <div
            className="Mon_font accordion accordion-flush mb-4"
            id="accordionFlushExample"
          >
            {dest?.itinerary?.map((item, index) => {
              return (
                <div key={index} className="accordion-item itinerary-item">
                  <div className="d-flex justify-content-center accordion-item-div">
                    <div className="accordion-item-day">Day {index + 1}</div>
                    <button
                      className="bg-light accordion-button collapsed px-0 accordian-item-button pe-2"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse-${index}`}
                      aria-expanded="true"
                      aria-controls={`collapse-${index}`}
                    >
                      <span className="ms-3">{item?.title}</span>
                    </button>
                  </div>
                  <div
                    id={`collapse-${index}`}
                    className="accordion-collapse collapse"
                    aria-labelledby={`heading-${index}`}
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body border m-2">
                      {item?.description?.split("\n").map((line, i) => (
                        <div key={i} className="my-2">
                          <span className="accordian-item-desc">{line}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )} */}

      {/* USEFUL INFORMATION */}
      {/* <div ref={usefulInfoRef} className="useful-info-container">
        <div className={`useful-info ${info && "active"}`}>
          <CloseIcon className="icon" onClick={() => setInfo(!info)} />
          <h1>Useful Information</h1>
          <div className="useful">
            {dest?.usefulInformation?.map((item, index) => {
              return (
                <div key={index} className={`description`}>
                  <p>
                    <span>
                      <b>{item?.title}</b>
                    </span>
                    {" - "}
                    {item?.description}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        <button onClick={() => setInfo(!info)} className="theme-btn">
          Useful Information <AddIcon className="ms-1" />
        </button>
      </div> */}
    </Layout>
  );
};

export default DestinationPage;
