import React, { useEffect, useState } from "react";
import { message } from "antd";
import Layout from "../components/Layout/Layout";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import "./Search.css";
import { Navigate, useNavigate } from "react-router-dom";

const Search = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [locations, setLocations] = useState([]);

  async function getLocations() {
    try {
      const res = await axios.get("/api/admin/get-locations");
      if (res.data.success) {
        setLocations(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getLocations();
  }, []);

  const filteredLocations = locations.filter((item) =>
    item?.tourName?.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Layout>
      <div className="search-page-container">
        <div className="search-container">
          <input
            type="text"
            className="form-control searchinput"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            placeholder="Search your location.."
          />
          <SearchIcon className="icon" />
        </div>

        {search && (
          <div className="search-box">
            {filteredLocations.length > 0 ? (
              filteredLocations.map((item, index) => (
                <div
                  key={index}
                  className="location-item"
                  onClick={() => navigate(`/destination/${item?.tourName}`)}
                >
                  {item.tourName}
                  <OpenInNewIcon className="icon" />
                </div>
              ))
            ) : (
              <div className="location-item">No locations found</div>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Search;
