import React, { useEffect, useState } from "react";
import { message } from "antd";
import Layout from "../components/Layout/Layout";
import axios from "axios";
import StarIcon from "@mui/icons-material/Star";
import IMAGES from "../img/image.js";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import "./Contact.css";

const Contact = () => {
  const [form, setForm] = useState({ name: "", email: "", mobile: "" });
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);

  function handleChange(e) {
    setForm({ ...form, [e.target.name]: e.target.value });
  }

  const fetchAddress = async () => {
    try {
      const res = await axios.get("/api/admin/get-address");
      if (res.data.success) {
        setData(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAddress();
  }, []);

  // const bannerImageUrl = `${dest?.bannerImage.replace(/\\/g, "/")}`;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (form?.name === "" || form?.email === "" || form?.mobile === "") {
      setError(true);
      return;
    }
    if (isNaN(form?.mobile)) {
      return message.error("Please enter only numbers in mobile");
    }
    if (form?.mobile?.length > 10 || form?.mobile.length < 10) {
      return message.error("Enter 10 digits in mobile");
    }
    try {
      const res = await axios.post("/api/contact/add-contact-form", form);
      if (res.data.success) {
        message.success(res.data.message);
        setForm({ name: "", email: "", mobile: "", msg: "" });
        setError(false);
      } else {
        message.error(res.data.message);
        setError(false);
      }
    } catch (error) {
      setError(false);
      console.log(error);
    }
  };

  return (
    <Layout>
      <div
        className="dest-container contact-container"
        style={{
          // background: `url(${bannerImageUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="bg-overlay">
          <div className="formandtitle">
            <div className="title">
              <h4>Contact Us</h4>
              <p>Our team will be very happy to assist you!</p>
            </div>
            <div className="cform">
              <p>
                Not sure what to do? We'll give you a <span>Call Back</span>
              </p>
              <div className="form-fields">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Enter your name"
                  onChange={handleChange}
                  value={form?.name}
                />
                {error && form?.name === "" && (
                  <span className="text-start text-danger d-block">
                    <small>Enter name</small>
                  </span>
                )}
              </div>
              <div className="form-fields">
                <label>Email</label>
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  placeholder="Enter your email"
                  onChange={handleChange}
                  value={form?.email}
                />
                {error && form?.email === "" && (
                  <span className="text-start text-danger d-block">
                    <small>Enter email</small>
                  </span>
                )}
              </div>
              <div className="form-fields">
                <label>Mobile</label>
                <input
                  type="text"
                  className="form-control"
                  name="mobile"
                  placeholder="Enter your phone"
                  onChange={handleChange}
                  value={form?.mobile}
                />
                {error && form?.mobile === "" && (
                  <span className="text-start text-danger d-block">
                    <small>Enter mobile</small>
                  </span>
                )}
              </div>
              <button onClick={handleSubmit}>Submit</button>
            </div>
            <div className="reviews address">
              <div className="address-item">
                <LocalPhoneIcon className="icon" />
                <span>{data?.mobile}</span>
              </div>
              <div className="address-item">
                <EmailIcon className="icon" />
                <span>{data?.email}</span>
              </div>
              <div className="address-item">
                <LocationOnIcon className="icon" />
                <span>{data?.address}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
