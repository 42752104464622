import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { message } from "antd";
import Layout from "../components/Layout/Layout";
import axios from "axios";
import IMAGES from "../img/image";
import Testimonials from "../components/Testimonials";
import WhyUs from "../components/Home/WhyUs";
import ContactForm from "../components/ContactForm";
import "./EventPage.css";
import ReactPlayer from "react-player";

const EventPage = () => {
  const { event } = useSelector((state) => state.event);
  const [locations, setlocations] = useState(null);

  const fetchLocation = async () => {
    try {
      const res = await axios.post("/api/location/get-locations", {
        category: "Event",
      });
      if (res.data.success) {
        setlocations(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchLocation();
  }, []);

  function handleWhatsapp(val) {
    const message = `I want to go ${val}. Please share the details!`;
    const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      message
    )}`;
    window.open(url, "_blank");
  }

  return (
    <Layout>
      <div className="event-page-container">
        <div className="eventimage">
          <video
            playsInline
            poster
            autoPlay
            muted
            loop
            src={event?.videoLink}
          ></video>
          <img className="bottomimage" src={event?.backgroundBottom} alt="" />
        </div>
        <div className="eventname">
          <h4>{event?.name}</h4>
          <p>{event?.description}</p>
          <img src={event?.midsection} alt="" />
          <button>
            {new Date(event?.fromDate).toLocaleString("default", {
              day: "numeric",
              month: "long",
            })}{" "}
            To{" "}
            {new Date(event?.toDate).toLocaleString("default", {
              day: "numeric",
              month: "long",
            })}
          </button>
        </div>
        <div className="eventlocations">
          {locations ? (
            <div className="category">
              <h2>Destinations</h2>
              <div className="alllocations">
                {locations &&
                  locations?.map((item, index) => {
                    return (
                      <div
                        className="locations"
                        onClick={() => handleWhatsapp(item?.tourName)}
                        data-tourname={item?.tourName}
                      >
                        <img src={item?.tourImage} alt="" />
                      </div>
                    );
                  })}
              </div>
            </div>
          ) : (
            <div className="nolocation">
              <h4>No Location Found</h4>
              <img src={IMAGES.nolocation} alt="" />
            </div>
          )}
        </div>
      </div>
      <Testimonials />
      <WhyUs />
      <ContactForm />
    </Layout>
  );
};

export default EventPage;
