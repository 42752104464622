import React from "react";
import { useNavigate } from "react-router-dom";
import "./MobilePackages.css";

const MobilePackages = ({ locations }) => {
  const navigate = useNavigate();
  const minLength = Math.ceil(locations?.length / 2);

  return (
    <div className="mobile-packages-container">
      <div className="lineone">
        {locations
          ?.map((item, index) => {
            return (
              <div
                className="mobile-location-item"
                onClick={() => navigate(`/destination/${item?.tourName}`)}
              >
                <div className="item-image">
                  <img src={item?.tourImage} alt="" />
                </div>
                <div className="item-name">
                  <span>{item?.tourName}</span>
                </div>
              </div>
            );
          })
          .splice(0, minLength)}
      </div>
      <div className="lineone mt-4">
        {locations
          ?.map((item, index) => {
            return (
              <div
                className="mobile-location-item"
                onClick={() => navigate(`/destination/${item?.tourName}`)}
              >
                <div className="item-image">
                  <img src={item?.tourImage} alt="" />
                </div>
                <div className="item-name">
                  <span>{item?.tourName}</span>
                </div>
              </div>
            );
          })
          .splice(minLength, locations?.length)}
      </div>
    </div>
  );
};

export default MobilePackages;
