import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import axios from "axios";
import { message, Table, Input, Button } from "antd";
import "./AdminCategory.css";

const AdminCategory = () => {
  const [page, setPage] = useState(0);
  const [category, setCategory] = useState(null);
  const [allCategory, setAllCategory] = useState(null);
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState(false);

  async function getCategory(e) {
    try {
      const res = await axios.get("/api/admin/get-category");
      if (res.data.success) {
        setAllCategory(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getCategory();
  }, []);

  async function handleAddCategory(e) {
    e.preventDefault();
    try {
      const res = await axios.post(
        "/api/admin/add-category",
        { category: category },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        getCategory();
        setCategory("");
        message.success(res.data.message);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function handleDelete(category) {
    const confirm = window.confirm("Are you sure to delete?");
    if (confirm) {
      try {
        const res = await axios.post(
          "/api/admin/delete-category",
          {
            category: category,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        if (res.data.success) {
          message.success(res.data.message);
          getCategory();
        } else {
          message.error(res.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  async function handleSave(e) {
    e.preventDefault();
    try {
      const res = await axios.post(
        "/api/admin/edit-category",
        {
          id: id,
          category: category,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
        setEdit(false);
        getCategory();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <AdminLayout>
      {page === 0 && (
        <div className="category-container">
          <div className="page-title">
            <h3 className="m-0">Category</h3>
            <button className="btn btn-success" onClick={() => setPage(1)}>
              Add Location
            </button>
          </div>
          <hr />
          <div className="add">
            <label className="form-label">Add Category</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter category"
              name="category"
              onChange={(e) => setCategory(e.target.value)}
            />
            <button onClick={handleAddCategory} className="btn btn-success">
              Add
            </button>
          </div>
          <div className="list"></div>
        </div>
      )}
      {/* CATEGORY  */}
      {page === 0 && (
        <table className="table table-bordered mt-4">
          <thead>
            <tr>
              <th>Srno</th>
              <th>Name</th>
              <th>Created At</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {allCategory
              ?.filter((item) => item?.category !== "Event")
              .map((item, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>
                      {edit && id === item?._id ? (
                        <input
                          type="text"
                          placeholder="edit"
                          value={category}
                          className="form-control"
                          onChange={(e) => setCategory(e.target.value)}
                        />
                      ) : (
                        item?.category
                      )}
                    </td>
                    <td>
                      {new Date(item?.createdAt).toLocaleString("default", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      })}
                    </td>
                    <td>
                      {edit && id === item?._id ? (
                        <button
                          className="btn btn-success"
                          onClick={handleSave}
                        >
                          Save
                        </button>
                      ) : (
                        <>
                          <button
                            className="btn btn-warning me-2"
                            onClick={() => {
                              setEdit(true);
                              setCategory(item?.category);
                              setId(item?._id);
                            }}
                          >
                            Edit
                          </button>
                          <button
                            className="btn btn-danger"
                            onClick={() => handleDelete(item?.category)}
                          >
                            Delete
                          </button>
                        </>
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}

      {/* LOCATION  */}
      {page === 1 && (
        <div className="location-container">
          <div className="page-title">
            <h3 className="m-0">Location</h3>
            <button className="btn btn-success" onClick={() => setPage(0)}>
              Add Category
            </button>
          </div>
          <hr />
          <div className="add">
            <label className="form-label">Add Location</label>
            <select name="location" className="form-select mb-2">
              <option value="">Select Category</option>
              {allCategory?.map((item, index) => {
                return <option value={item?.category}>{item?.category}</option>;
              })}
            </select>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Location"
              name="category"
            />
            <button className="btn btn-success">Add</button>
          </div>
          <div className="list"></div>
        </div>
      )}
      {page === 1 && (
        <table className="table table-bordered mt-4">
          <thead>
            <tr>
              <th>Srno</th>
              <th>Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      )}
    </AdminLayout>
  );
};

export default AdminCategory;
